import { Chip, Css, IconButton, TabWithContent, TabsWithContent, useBreakpoint, useTestIds } from "@homebound/beam";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Price } from "src/components";
import { PageHeader } from "src/routes/layout/PageHeader";
import { purchaseOrderParam } from "src/routes/routesDef";
import { commitmentStatusToChipTypeMapper, isDefined } from "src/utils";
import { PurchaseOrderDetailsTab } from "./PurchaseOrderDetailsTab";
import { PurchaseOrderInvoicesTab } from "./PurchaseOrderInvoicesTab";
import { PurchaseOrderSpecsTab } from "./PurchaseOrderSpecsTab";
import { CommitmentLikeType } from "./utils";

type PurchaseOrderDetailPaneProps = {
  commitmentLike: CommitmentLikeType;
  handleClose: () => void;
};

export function PurchaseOrderDetailPane(props: PurchaseOrderDetailPaneProps) {
  const { commitmentLike, handleClose } = props;
  const { changeOrderId } = useParams<purchaseOrderParam>();
  const { mdAndDown } = useBreakpoint();
  const [selectedTab, setTab] = useState("details");
  const [type, text] = commitmentStatusToChipTypeMapper(commitmentLike.status);
  const isChangeOrder = isDefined(changeOrderId);
  const tid = useTestIds({});

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: "details",
      render: () => (
        <div {...tid.detailsTab}>
          <PurchaseOrderDetailsTab />
        </div>
      ),
    },
    {
      name: "Specs",
      value: "specs",
      render: () => (
        <div {...tid.specsTab}>
          <PurchaseOrderSpecsTab />
        </div>
      ),
    },
    {
      name: "Invoices",
      value: "invoices",
      render: () => (
        <div {...tid.invoicesTab}>
          <PurchaseOrderInvoicesTab />
        </div>
      ),
    },
  ];

  return (
    <div css={Css.px4.py3.oa.h100.f1.$}>
      <div css={Css.df.fdc.$}>
        <div css={Css.ifMdAndDown.df.fdr.$}>
          <div>
            <Chip type={type} text={text} compact />
          </div>
          {mdAndDown && (
            <div css={Css.mla.$}>
              <IconButton icon="x" inc={5} onClick={handleClose} />
            </div>
          )}
        </div>

        <PageHeader
          xss={Css.bn.xl.my3.$}
          left={<div css={Css.xlSb.$}>{`${isChangeOrder ? "CO" : "PO"}#${commitmentLike.accountingNumber}`}</div>}
          right={<Price valueInCents={commitmentLike.committedInCents} />}
        />
      </div>
      <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setTab} />
    </div>
  );
}
