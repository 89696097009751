import {
  Button,
  Css,
  FieldGroup,
  FormLines,
  MultiSelectField,
  SelectField,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { QueryResultHandler } from "src/components";
import { useQueryStorage } from "src/components/useQueryStorage";
import {
  CurrentUserDocument,
  ImpersonateTradeUserModalQuery,
  InvoicesPageDocument,
  PurchaseOrdersPageDocument,
  SchedulesPageDocument,
  useImpersonateTradeUserModalQuery,
  useImpersonateTradeUserModal_SaveInternalUserMutation,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";

interface ImpersonateTradeUserProps extends ImpersonateTradeUserModalQuery {
  close: () => void;
}

export function ImpersonateTradeUserModal({ close }: { close: VoidFunction }) {
  const query = useImpersonateTradeUserModalQuery();
  return QueryResultHandler<ImpersonateTradeUserModalQuery>({
    result: query,
    render: ({ tradePartners }) => <ImpersonateTradeUserModalView {...{ tradePartners, close }} />,
  });
}

export function ImpersonateTradeUserModalView(props: ImpersonateTradeUserProps) {
  const { tradePartners, close } = props;
  const { triggerNotice } = useSnackbar();
  const [saveInternalUser] = useImpersonateTradeUserModal_SaveInternalUserMutation();
  const tid = useTestIds({});

  // Allows selected filter values to persist per browser session
  const { queryStorage: filter, setQueryStorage: setFilter } = useQueryStorage<ImpersonateTradeUserFilter>({
    storageKey: "impersonateTradeUserFilter",
    initialQueryStorage: impersonateUserFilterDefault,
  });

  const [modalFilter, setModalFilter] = useState<ImpersonateTradeUserFilter>({
    ...filter,
  });

  const tradeUsers = useMemo(
    () =>
      tradePartners
        .flatMap((tp) => tp.users)
        .filter((tpu) => modalFilter.tradePartners.isEmpty || modalFilter.tradePartners.includes(tpu.tradePartner.id))
        .filter((tpu) => tpu.tradePartnerContact?.isActive),
    [modalFilter.tradePartners, tradePartners],
  );

  const onSubmit = useCallback(async () => {
    setFilter(modalFilter);
    const { data } = await saveInternalUser({
      variables: { input: { impersonateTradePartnerUserId: modalFilter.tradeUser } },
      refetchQueries: [
        // rehydrate the page for the new impersonated user
        { query: PurchaseOrdersPageDocument },
        { query: InvoicesPageDocument },
        { query: CurrentUserDocument },
        { query: SchedulesPageDocument },
      ],
    });

    if (data?.saveInternalUser.internalUser) {
      const newTpu = tradeUsers.find((tpu) => modalFilter.tradeUser === tpu.id);
      triggerNotice({
        message: `Successfully impersonating ${newTpu?.tradePartnerContact?.name}`,
      });
    }
    close();
  }, [close, modalFilter, saveInternalUser, setFilter, tradeUsers, triggerNotice]);

  return (
    <>
      <div {...tid.title}>Select a trade user to impersonate</div>
      <div css={Css.my3.$}>
        <FormLines>
          <FieldGroup>
            <MultiSelectField
              onSelect={(val) =>
                setModalFilter((prevState) => ({
                  ...prevState,
                  tradePartners: val.nonEmpty ? val : [],
                }))
              }
              label="Trade Partner"
              options={tradePartners.filter((tp) => tp.users.nonEmpty)}
              values={modalFilter.tradePartners}
            />
            <SelectField
              onSelect={(val) =>
                setModalFilter((prevState) => ({
                  ...prevState,
                  tradeUser: isDefined(val) ? val : undefined,
                }))
              }
              label="Trade User"
              options={tradeUsers}
              getOptionLabel={(tpu) => tpu.tradePartnerContact?.name || ""}
              value={modalFilter.tradeUser}
            />
          </FieldGroup>
        </FormLines>
      </div>
      <div css={Css.df.gap1.jcfe.mtPx(30).$}>
        <Button variant="tertiary" label="Cancel" onClick={close} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </>
  );
}

type ImpersonateTradeUserFilter = {
  tradePartners: string[];
  tradeUser: string | undefined;
};

const impersonateUserFilterDefault: ImpersonateTradeUserFilter = {
  tradePartners: [],
  tradeUser: undefined,
};
