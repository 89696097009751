/** A function that calls `window.open` so we can `jest.mock` it out. */
export function openNewTab(url: string): void {
  window.open(url, "_blank");
}

/**
 * openInSelf means if there's any errors users are taken away from the page they were on. Such
 * errors could include "Please deselect <invalid CO to download>" but they'd have to hit Back
 * and redo all their filters to unselect the right thing and try again.
 *
 * And if the download works then the new tab should auto-close after the download happens since
 * the BE's Content-Disposition: attachment should be telling the browser to close the tab.
 */
export const openDownload = openNewTab;

/** A function that calls `window.open` so we can `jest.mock` it out. */
export function openInSelf(url: string): void {
  window.open(url, "_self");
}
