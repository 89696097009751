import { PotentialOperation2 } from "../../generated/graphql-types";

export function disableBasedOnPotentialOperation(potentialOperation: PotentialOperation2) {
  if (potentialOperation.allowed) return false;

  return (
    <>
      {potentialOperation.disabledReasons.map((dr, i) => (
        <div key={i}>{dr.message}</div>
      ))}
    </>
  );
}
