import { createContext, MutableRefObject, PropsWithChildren, useContext, useMemo, useRef } from "react";

interface TradesContextProps {
  headerActionsRef: MutableRefObject<HTMLDivElement | null> | null;
  headerActionsEl: HTMLDivElement | null;
}

export const TradesContext = createContext<TradesContextProps>({
  headerActionsRef: null,
  headerActionsEl: null,
});

export function TradesProvider({ children }: PropsWithChildren<unknown>) {
  const headerActionsRef = useRef<HTMLDivElement | null>(null);
  const headerActionsEl = useMemo(() => document.createElement("div"), []);
  const context: TradesContextProps = { headerActionsRef, headerActionsEl };

  return <TradesContext.Provider value={context}>{children}</TradesContext.Provider>;
}

export function useTradesContext() {
  return useContext(TradesContext);
}
