import { Css, Palette, useBreakpoint, useTestIds } from "@homebound/beam";
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import { HeroMetric } from "src/components/HeroMetrics";
import { capitalizeFirstWord } from "src/utils";

/** Metric chart that displays pie diagram based on dataset & a corresponding totals hero metric*/
interface PieChartProps {
  title: string;
  total: number;
  dataset: Record<string, number>;
}

export function PieChart({ title, total, dataset }: PieChartProps) {
  const legendData = useMemo(() => getLegendData(dataset), [dataset]);
  const colors = useMemo(() => getLegendColors(Object.values(legendData).map(({ color }) => color)), [legendData]);
  const formattedData = Object.entries(dataset).map(([k, v]) => ({ id: k, value: v }));
  const { mdAndDown } = useBreakpoint();
  const tid = useTestIds({}, "pieChart");

  return (
    <div css={Css.df.p1.$}>
      <div css={Css.fg1.$}>
        <div css={Css.df.fdr.jcsa.$}>
          <div css={Css.df.fdc.gap1.$} {...tid.herometric}>
            <span css={Css.xs.gray700.ptPx(10).$}>{title}</span>
            <HeroMetric value={total} size={mdAndDown ? "sm" : "md"} />
          </div>

          <div css={Css.wPx(76).hPx(76).ifMdAndDown.wPx(40).hPx(40).mya.mr3.$} {...tid.pie}>
            <Chart dataset={formattedData} colors={colors} disabled={!total} />
          </div>

          <ChartLegend legendData={legendData} />
        </div>
      </div>
    </div>
  );
}

function Chart(props: { dataset: { id: string; value: number }[]; colors: string[]; disabled?: boolean }) {
  const { dataset, colors } = props;
  return (
    <ResponsivePie
      data={dataset}
      innerRadius={0.5}
      colors={colors}
      enableRadialLabels={false}
      enableSlicesLabels={false}
    />
  );
}

export function ChartLegend({ legendData }: { legendData: LegendData }) {
  const tid = useTestIds(legendData, "chartslegend");
  return (
    <div css={Css.$} {...tid.legend}>
      {Object.entries(legendData).map(([id, { name, value, color }]) => (
        <div key={id} css={Css.dg.gtc("1fr 1fr").gap4.my1.$}>
          <div css={Css.df.aic.gap1.$}>
            <div
              css={Css.h1.w1.br100.add({ ...(Css[`bg${color}` as keyof typeof Css] as typeof Css).$ }).$}
              {...tid[`${name.replace(/ /g, "_")}_color`]}
            />
            <div css={Css.tiny.gray700.$}>{name}</div>
          </div>
          <HeroMetric value={value} size="xs" />
        </div>
      ))}
    </div>
  );
}

function getLegendData(dataset: PieChartProps["dataset"]) {
  const data = Object.entries(dataset).map(([k, value], i) => ({ id: i + 1, name: capitalizeFirstWord(k), value }));
  const colors = ["Blue200", "Blue400", "Blue600", "Blue800"];
  const legendData: LegendData =
    data.reduce(
      (prev, { id, name, value }, index) => ({
        ...prev,
        [id]: {
          name,
          value,
          color: index >= colors.length ? colors[Math.floor(Math.random() * colors.length)] : colors[index],
        },
      }),
      {},
    ) ?? {};
  return legendData;
}

function getLegendColors(colors: string[]) {
  return colors.map(
    (color) =>
      (Palette.hasOwnProperty(color) &&
        // @ts-ignore
        Palette[color]) ||
      color,
  );
}

export type LegendData = Record<
  string,
  {
    name: string;
    value: number;
    color: string;
  }
>;
