import { Css, Icon, IconButton, Palette, useTestIds } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { FormattedDate, IconListStyle, IconsList, QueryResultHandler, UserList } from "src/components";
import {
  PurchaseOrderDetailPane_DetailsTabQuery,
  PurchaseOrderDetailPane_DocumentFragment,
  usePurchaseOrderDetailPane_DetailsTabQuery,
} from "src/generated/graphql-types";
import { purchaseOrderParam } from "src/routes/routesDef";
import { isDefined } from "src/utils";

export function PurchaseOrderDetailsTab() {
  const { purchaseOrderId, changeOrderId } = useParams<purchaseOrderParam>();
  const query = usePurchaseOrderDetailPane_DetailsTabQuery({
    variables: {
      commitmentId: purchaseOrderId ?? "",
      changeOrderId: changeOrderId ?? "",
      // Use a skip directive inline to prevent querying a commitment/cco depending on which page we've requested
      // Allows us to also handle the request for one or the other in the same query
      skipCommitment: !!changeOrderId,
      skipChangeOrder: !changeOrderId,
    },
  });

  return QueryResultHandler<PurchaseOrderDetailPane_DetailsTabQuery>({
    result: query,
    render: PurchaseOrderDetailsTabView,
  });
}

function PurchaseOrderDetailsTabView(props: PurchaseOrderDetailPane_DetailsTabQuery) {
  const { commitment, commitmentChangeOrder } = props;

  const commitmentLike = commitment ?? commitmentChangeOrder;
  const isChangeOrder = isDefined(commitmentChangeOrder);
  const coSentDate = commitmentChangeOrder?.sentDate ?? commitmentChangeOrder?.executionDate;
  const sentDate = coSentDate ?? commitment?.sentDate ?? commitment?.executionDate;
  const { buildAddress, teamMembers } =
    (isChangeOrder ? commitmentChangeOrder?.commitment.project : commitment?.project) ?? {};

  return (
    <>
      <IconsList
        list={[
          { icon: "house", value: buildAddress?.street1 },
          { icon: "calendar", date: { label: "Released on", value: sentDate } },
        ]}
        listStyle={IconListStyle.SimpleList}
      />
      <UserList users={teamMembers || []} title="Contacts" />
      {commitmentLike?.document?.asset && <DocumentList assets={[commitmentLike?.document?.asset]} />}
    </>
  );
}

export function DocumentList({
  assets,
}: {
  assets: (PurchaseOrderDetailPane_DocumentFragment["asset"] | undefined)[];
}) {
  const tid = useTestIds({});
  return (
    <div css={Css.mt3.$}>
      <div css={Css.smMd.$}>Documents</div>
      <div css={Css.mt3.$}>
        {assets.map((a, i) => (
          <div css={Css.df.fdc.w100.mb1.br8.$} key={`${a?.id}-${i}`}>
            <div css={Css.dg.gtc("45px 245px 40px").gap2.tiny.p2.asfe.aic.br8.addIn("&:hover", Css.bgGray100.$).$}>
              <Icon icon="document" inc={3} />
              <div css={Css.df.fdc.$}>
                <div css={Css.mb1.tinyMd.$} {...tid.documentName}>
                  {a?.fileName}
                </div>
                <FormattedDate date={a?.createdAt} dateFormatStyle="short" timeFormatStyle="short" />
              </div>
              {a?.downloadUrl && <IconButton color={Palette.Blue500} inc={3} icon="download" onClick={a.downloadUrl} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
