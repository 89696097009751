import { Css, Icon, Tooltip, Typography, Xss, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";

import { isDefined } from "src/utils";
import { centsToDollars, formatNumberToString, isNegative, roundCentsToDollars } from "src/utils/numbers";

/**
 * Reuseability of this component hasn't yet been decided upon by design team
 * Standardized category of metric sizes/subtitles are still wip
 */
interface HeroMetricProps {
  value: number;
  subTitle1?: ReactNode;
  subTitle2?: ReactNode;
  isPercent?: boolean;
  /** Display the metric value as green based on if the value is negative or positive*/
  greenIf?: "positive" | "negative";
  size?: HeroSize;
  colorXss?: Xss<"color">;
  toolTipText?: ReactNode;
  rounded?: boolean;
  /** Display the plus prefix for positive numberes */
  displayDirection?: boolean;
  /** If false minimal decimal is 0, max decimal is 2 (default = max,min decimal is 2 ) */
  trim?: boolean;
}

export function HeroMetric({
  value,
  subTitle1,
  subTitle2,
  isPercent,
  greenIf,
  size,
  colorXss,
  toolTipText,
  rounded = true,
  displayDirection,
  trim = true,
}: HeroMetricProps) {
  const tid = useTestIds({});
  const colorStyle = colorXss
    ? colorXss
    : greenIf === "positive"
    ? Css.if(isNegative(value)).red600.else.green800.$
    : greenIf === "negative"
    ? Css.if(isNegative(value) || value === 0).green800.else.red600.$
    : undefined;
  const prefix = isNegative(value) ? "-$" : displayDirection && !isNegative(value) ? "+$" : "$";
  const formatted = formatNumberToString(
    rounded ? roundCentsToDollars(Math.abs(value || 0)) : centsToDollars(Math.abs(value)),
    trim,
  );

  return (
    <div css={Css.df.$}>
      {/** Subscript style price prefix i.e. -$ or +$ */}
      {!isPercent && <div css={{ ...colorStyle, ...Css.asfs[size ? prefixSizeToType[size] : "smBd"].$ }}>{prefix}</div>}
      <div css={Css.df.fdc.$} {...tid.subTitles}>
        <div
          {...tid.metricValue}
          css={{
            ...(isDefined(size)
              ? { ...colorStyle, ...Css[metricSizeToType[size]].$ }
              : { ...colorStyle, ...Css.xl3Bd.$ }),
          }}
        >
          {isPercent ? (
            <div>
              <span>{formatNumberToString(value, true)}</span>
              {/** Subscript style percentage sign*/}
              <span css={Css.baseBd.$}>%</span>
            </div>
          ) : (
            formatted
          )}
        </div>
        <div css={Css.df.fdc.$}>
          <div css={Css.df.fdr.aic.gap1.$}>
            <div css={{ ...(isDefined(size) ? Css[subTitleSizeToType[size]].$ : Css.smBd.$) }}>{subTitle1}</div>
            {toolTipText && (
              <Tooltip placement="top" title={toolTipText}>
                <Icon icon="infoCircle" inc={2} />
              </Tooltip>
            )}
          </div>
          <div css={{ ...(isDefined(size) ? Css[subTitleSizeToType[size]].$ : Css.tiny.$) }}>{subTitle2}</div>
        </div>
      </div>
    </div>
  );
}

export type HeroSize = "xs" | "sm" | "md" | "lg";

const metricSizeToType: Record<HeroSize, Typography> = {
  xs: "xsSb",
  sm: "xlSb",
  md: "xl2Sb",
  lg: "xl3",
};

const subTitleSizeToType: Record<HeroSize, Typography> = {
  xs: "tinySb",
  sm: "smSb",
  md: "base",
  lg: "baseSb",
};

const prefixSizeToType: Record<HeroSize, Typography> = {
  xs: "tiny",
  sm: "base",
  md: "baseSb",
  lg: "lg",
};
