import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  BoundToggleChipGroupField,
  Button,
  Css,
  FieldGroup,
  FormDivider,
  FormLines,
  HasIdAndName,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { reaction } from "mobx";
import { Observer } from "mobx-react";
import { Fragment, useCallback, useMemo } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import {
  ContactsTabTradePartnerContactFragment,
  SaveTradePartnerContactInput,
  useDeleteTradePartnerContactMutation,
  useSaveTradePartnerContactMutation,
} from "src/generated/graphql-types";
import { USStates, emailFormatRule } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

export interface TradePartnerContactModalProps {
  tradePartnerId: string;
  contact: ContactsTabTradePartnerContactFragment | undefined;
  markets: HasIdAndName[];
}

export function TradePartnerContactModal(props: TradePartnerContactModalProps) {
  const { contact, markets, tradePartnerId } = props;
  const { closeModal } = useModal();
  const [saveContact] = useSaveTradePartnerContactMutation({ refetchQueries: ["TradePartnerContacts"] });
  const tid = useTestIds(props);

  const form = useFormState({
    config: formConfig,
    init: {
      input: contact,
      map: (contact) => ({
        ...contact,
        marketIds: contact.markets.map((m) => m.id),
      }),
    },
  });

  // Conditionally add/remove `required` to the address fields. Using `reaction` instead of `autorun`
  // so that we can be very explicit about only re-running when the signatory role changes.
  useMemo(() => {
    return reaction(
      () => form.maybeBillingSignatory.value,
      () => {
        const maybeBillingSignatory = form.maybeBillingSignatory.value;
        const { country, state, street1, city, postalCode } = form.address;
        [country, state, street1, city, postalCode].forEach((f) => {
          if (maybeBillingSignatory && !f.required) {
            f.rules.push(required);
          } else if (!maybeBillingSignatory && f.required) {
            f.rules.splice(0, 1);
            f.set(undefined);
          }
        });
        if (maybeBillingSignatory) {
          form.address.country.set("US");
        }
      },
      { fireImmediately: true },
    );
  }, [form]);

  return (
    <Fragment>
      <ModalHeader>{contact ? "Edit Contact" : "Create Contact"}</ModalHeader>
      <ModalBody>
        <FormLines width="full" labelSuffix={{ required: "*" }}>
          <FieldGroup>
            <BoundTextField field={form.name} />
            <BoundTextField field={form.title} />
          </FieldGroup>
          <BoundTextField field={form.email} />
          <FieldGroup>
            <BoundTextField field={form.officePhone} label="Office Phone" />
            <BoundTextField field={form.mobilePhone} label="Mobile Phone" />
          </FieldGroup>
          <BoundToggleChipGroupField
            field={form.marketIds}
            label="Markets"
            options={markets.map((m) => ({ label: m.name, value: m.id }))}
          />
          <div css={Css.pt1.$}>
            <BoundSwitchField field={form.canLoginToPortal} label="Enable HomeboundTrade Access" labelStyle="filter" />
            <div css={Css.baseSb.mt3.$}>Roles</div>
            <div css={Css.xs.gray900.mt1.mb2.$}>
              Turn on user role abilities below. Assign roles for each market in the “Role by Market” section.
            </div>
            <Observer>
              {() => (
                <>
                  <BoundSwitchField
                    field={form.maybeBillingSignatory}
                    label="Contract and PO Signatory"
                    labelStyle="filter"
                  />
                  {form.maybeBillingSignatory.value && (
                    <div css={Css.mt2.mb5.$}>
                      <FormDivider />
                      <div css={Css.gray700.my3.sm.$}>
                        For contract and PO signatories, please enter their business address.
                      </div>
                      <FormLines>
                        <BoundTextField field={form.address.street1} label="Street" />
                        <FieldGroup>
                          <BoundTextField field={form.address.city} />
                          {form.address.country.value === "US" ? (
                            <BoundSelectField
                              field={form.address.state}
                              options={USStates}
                              getOptionLabel={(o) => o.name}
                              getOptionValue={(o) => o.name}
                            />
                          ) : (
                            <BoundTextField field={form.address.state} />
                          )}
                        </FieldGroup>
                        <FieldGroup>
                          <BoundTextField field={form.address.postalCode} />
                          <BoundSelectField
                            field={form.address.country}
                            options={countries}
                            getOptionLabel={(o) => o.name}
                            getOptionValue={(o) => o.name}
                          />
                        </FieldGroup>
                      </FormLines>
                    </div>
                  )}
                </>
              )}
            </Observer>
          </div>
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <TradePartnerContactDeleteOrDeactivateButton contact={contact} />
        <Observer>
          {() => (
            <Button
              label="Save Contact"
              disabled={!form.valid}
              onClick={async () => {
                if (form.canSave()) {
                  await saveContact({ variables: { input: { ...form.changedValue, tradePartnerId } } });
                }
                closeModal();
              }}
              {...tid.save}
            />
          )}
        </Observer>
      </ModalFooter>
    </Fragment>
  );
}

const countries = [{ name: "US" }, { name: "Bahamas" }];

type FormValue = SaveTradePartnerContactInput;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  email: { type: "value", rules: [emailFormatRule] },
  title: { type: "value" },
  officePhone: { type: "value" },
  mobilePhone: { type: "value" },
  canLoginToPortal: { type: "value" },
  maybeBillingSignatory: { type: "value" },
  marketIds: { type: "value" },
  address: {
    type: "object",
    config: {
      street1: { type: "value" },
      city: { type: "value" },
      state: { type: "value" },
      postalCode: { type: "value" },
      country: { type: "value" },
    },
  },
};

function TradePartnerContactDeleteOrDeactivateButton({
  contact,
}: {
  contact: ContactsTabTradePartnerContactFragment | undefined;
}) {
  const { openModal, closeModal } = useModal();
  const [deleteTpcMutation] = useDeleteTradePartnerContactMutation({
    variables: { input: { id: contact?.id ?? "" } },
    refetchQueries: ["TradePartnerContacts"],
  });
  const { triggerNotice } = useSnackbar();

  const onClick = useCallback(async () => {
    await deleteTpcMutation();
    triggerNotice({
      icon: "success",
      message: `Trade partner contact ${contact?.canDelete.allowed ? "deleted" : "deactivated"} successfully`,
    });
    closeModal();
  }, [closeModal, contact?.canDelete.allowed, deleteTpcMutation, triggerNotice]);

  if (!contact) return null;
  if (contact.canDelete.allowed)
    return (
      <Button
        label="Delete Contact"
        variant="danger"
        onClick={() =>
          openModal({
            content: (
              <ConfirmationModal
                title={`Delete contact ${contact?.name}`}
                confirmationMessage="This will delete the trade partner contact and cannot be undone"
                label="Delete"
                danger
                onConfirmAction={onClick}
              />
            ),
          })
        }
      />
    );
  // else assume Deactivate workflow
  return (
    <Button
      label="Deactivate Contact"
      variant="danger"
      disabled={
        !contact.canDeactivate.allowed ? contact.canDeactivate.disabledReasons.map((r) => r.message).join() : undefined
      }
      onClick={onClick}
    />
  );
}
