import { HomeboundUser, currentUser } from "@homebound/auth-components";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "./Routes";
import { useCurrentUserQuery } from "./generated/graphql-types";
import { PageLayout } from "./routes/layout/PageLayout";
import { setAnalyticsUser } from "./utils";

export function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<HomeboundUser>();
  const signInPath = "/login";
  const { data } = useCurrentUserQuery();

  useEffect(() => {
    setAnalyticsUser(data?.currentUser);
  }, [data?.currentUser]);

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  async function onLoad() {
    const { data: user } = await currentUser();
    setUser(user);
    setIsAuthenticated(user !== undefined);
    setIsAuthenticating(false);
  }

  const authProps = { isAuthenticated, setIsAuthenticated, user, setUser, signInPath };
  const routes = <Routes authProps={authProps} />;

  if (isAuthenticating) {
    return <div />;
  } else if (isAuthenticated) {
    return (
      <Switch>
        <Route>
          <PageLayout {...{ user, setIsAuthenticated, signInPath }}>{routes}</PageLayout>
        </Route>
      </Switch>
    );
  } else {
    return <Switch>{routes}</Switch>;
  }
}
