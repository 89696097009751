import { Css, px, useTestIds } from "@homebound/beam";
import { qaServerEnvironment } from "src/utils";
import { topNavHeight } from "./GlobalNav";

export function QaEnvironmentWarning() {
  const testId = useTestIds({}, "qaEnvironmentWarning");
  const { qaCustomBeBranch } = qaServerEnvironment();

  const message = qaCustomBeBranch
    ? `You are in the QA environment for the feature branch ${qaCustomBeBranch} populated with production data. Any changes here will not be reflected on the live production OR the standard QA environment data.`
    : `You are in the QA environment populated with production data. Any changes here will not be reflected on the live production data.`;
  return (
    <div css={Css.bgBlue200.fs0.w100.sticky.top(px(topNavHeight)).z999.h(px(48)).df.aic.$} {...testId}>
      <div css={Css.mx3.$}>{message}</div>
    </div>
  );
}
