// https://www.timeanddate.com/calendar/monthly.html?year=2018&month=1&country=1
import { parseISO, subDays, subWeeks } from "date-fns";
import { DateOnly } from "../dates";

export const jan1 = new DateOnly(new Date(2018, 0, 1));
export const jan2 = new DateOnly(new Date(2018, 0, 2));
export const jan3 = new DateOnly(new Date(2018, 0, 3));
export const jan4 = new DateOnly(new Date(2018, 0, 4));
export const jan5 = new DateOnly(new Date(2018, 0, 5));
export const jan6 = new DateOnly(new Date(2018, 0, 6));
export const jan7 = new DateOnly(new Date(2018, 0, 7));
export const jan8 = new DateOnly(new Date(2018, 0, 8));
export const jan9 = new DateOnly(new Date(2018, 0, 9));
export const jan10 = new DateOnly(new Date(2018, 0, 10));
export const jan11 = new DateOnly(new Date(2018, 0, 11));
export const jan12 = new DateOnly(new Date(2018, 0, 12));
export const jan13 = new DateOnly(new Date(2018, 0, 13));
export const jan14 = new DateOnly(new Date(2018, 0, 14));
export const jan15 = new DateOnly(new Date(2018, 0, 15));
export const jan16 = new DateOnly(new Date(2018, 0, 16));
export const jan17 = new DateOnly(new Date(2018, 0, 17));
export const jan18 = new DateOnly(new Date(2018, 0, 18));
export const jan19 = new DateOnly(new Date(2018, 0, 19));
export const jan20 = new DateOnly(new Date(2018, 0, 20));
export const dec18 = new DateOnly(subWeeks(jan1, 2));
export const dec25 = new DateOnly(subWeeks(jan1, 1));
export const dec31 = new DateOnly(subDays(jan1, 1));
export const jan1Midnight = parseISO("2018-01-01T00:00:00.000Z");

export const jan1_3000 = new Date(3000, 0, 1);
export const jan1_3000_do = new DateOnly(jan1_3000);

export function newDate() {
  return jan1;
}

export function newDateTime() {
  return jan1Midnight;
}
