import { Css, Icon, Palette, TextField, useTestIds } from "@homebound/beam";
import { useEffect, useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";

interface SearchBoxProps {
  onSearch(filter: string): void;
  fullWidth?: boolean;
  clearable?: boolean;
  placeholder?: string;
  debounceDelayInMs?: number;
}

export function SearchBox(props: SearchBoxProps) {
  const { onSearch, fullWidth, clearable, debounceDelayInMs, placeholder = "Search" } = props;

  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce(value, debounceDelayInMs);
  const tid = useTestIds(props, "searchBox");

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue, onSearch]);

  return (
    // 244px width per design specifications
    <div css={fullWidth ? Css.w100.$ : Css.wPx(244).$}>
      <TextField
        label="Search"
        labelStyle="hidden"
        compact
        value={value}
        onChange={(v) => setValue(v ?? "")}
        placeholder={placeholder}
        clearable={clearable}
        startAdornment={<Icon icon="search" color={Palette.Gray700} />}
        {...tid}
      />
    </div>
  );
}
