import { Css, useBreakpoint, useTestIds } from "@homebound/beam";
import { TradePartnerContactFragment } from "src/generated/graphql-types";
import { HomeboundLogo } from "./HomeboundLogo";

export function SignatoryFooter({ tradeContact }: { tradeContact: TradePartnerContactFragment | undefined | null }) {
  const { lg: large } = useBreakpoint();
  const tid = useTestIds({}, "footer");
  return (
    <div css={Css.bt.mt8.bcGray300.bw1.$}>
      <div css={Css.dg.tiny.gap4.mt5.if(large).gtc("200px 240px 200px").$}>
        <div css={Css.mt3.$} {...tid.logo}>
          <HomeboundLogo width={100} height={31} />
        </div>

        <div css={Css.df.fdc.jcfs.aifs.$} {...tid.hbAccountingInfo}>
          <div css={Css.gray600.mb1.$}>Pay from</div>
          <div>Homebound</div>
          <div>1 Letterman Dr, C-3500</div>
          <div>San Francisco, CA 94219</div>
          <div>901-566-6789</div>
          <a
            href="mailto:accounting@homebound.com"
            css={Css.tiny.addIn("&:hover", Css.textDecoration("none").bb.bcWhite.pb2.$).$}
          >
            accounting@homebound.com
          </a>
        </div>

        <div css={Css.df.fdc.jcfs.aifs.$} {...tid.tradeSignatoryInfo}>
          <div css={Css.gray600.mb1.$}>Pay to</div>
          <div>{tradeContact?.tradePartner.name}</div>
          <div>{tradeContact?.email}</div>
          <a
            href={`mailto:${tradeContact?.email}`}
            css={Css.tiny.addIn("&:hover", Css.textDecoration("none").bb.bcWhite.pb2.$).$}
          >
            {tradeContact?.email}
          </a>
        </div>
      </div>
    </div>
  );
}
