import { Css, useBreakpoint, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { FormattedDate } from "src/components";
import { PieChart } from "src/components/PieChart";
import { SummaryMetric } from "src/components/SummaryMetric";
import { BillStatus, InvoicesPageQuery } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks";
import { lastSunday, pluralize, twoSundaysAgo } from "src/utils";
import { isApprovedStatus } from "./utils";

export function InvoicesSummary(props: InvoicesPageQuery) {
  const { bills } = props;
  const user = useCurrentUser();
  const { sm } = useBreakpoint();

  // `Invoiced This week` metric
  const [sentThisWeek, othersSent] = bills.partition((b) => !!b.sentDate && b.sentDate > lastSunday);
  const sentLastWeek = othersSent.filter((b) => b.sentDate && b.sentDate > twoSundaysAgo && b.sentDate < lastSunday);

  // `Needs Approval` metric
  const needApprovalStatuses = [BillStatus.PendingApproval, BillStatus.ChangesRequested];
  const needApprovals = bills.filter((b) => needApprovalStatuses.includes(b.status.code));

  // `Processing Invoices` metric
  // `Processing` are bills that have been approved but not yet paid
  // Filter to get already approved bills for now w/bill date > than last sunday temp
  const approvedBills = useMemo(() => bills.filter((b) => isApprovedStatus(b.status.code)), [bills]);
  const [processingThisWeek, othersProcessing] = approvedBills.partition(
    (b) => !!b.approvedAt && b.approvedAt > lastSunday && !b.paidDate,
  );
  const processingLastWeek = othersProcessing.filter(
    (b) => !!b.approvedAt && b.approvedAt > twoSundaysAgo && b.approvedAt < lastSunday && !b.paidDate,
  );

  // Pie metric bills data.
  const activeBills = useMemo(
    () => bills.filter((b) => ![BillStatus.Rejected, BillStatus.Reversed].includes(b.status.code)),
    [bills],
  );

  const tid = useTestIds({}, "invoiceSummary");

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.smMd.df.fdr.$} {...tid.greeting}>
        {user.name && `Hi ${user?.name}! Today is`}
        &nbsp;
        <FormattedDate date={new Date()} dateFormatStyle="xshort" />
      </div>

      <div css={Css.dg.gtc("1fr 1fr").gap1.my3.ifSm.df.fdc.$}>
        <div css={Css.dg.gtc("1fr 1fr 1fr").bgWhite.p3.bshBasic.jic.if(!sm).mr2.$}>
          <SummaryMetric
            title="Invoiced this week"
            metric={`${sentThisWeek.length} ${pluralize(sentThisWeek.length, "invoice")}`}
            delta={sentThisWeek.length - sentLastWeek.length}
          />
          <SummaryMetric
            title="Needs Approval"
            metric={`${needApprovals.length} ${pluralize(needApprovals.length, "invoice")}`}
          />
          <SummaryMetric
            title="Processing"
            metric={`${processingThisWeek.length} ${pluralize(processingThisWeek, "invoice")}`}
            delta={processingThisWeek.length - processingLastWeek.length}
          />
        </div>

        <div css={Css.dg.bgWhite.p2.bshBasic.$}>
          <PieChart
            title="Total invoiced"
            total={activeBills.sum((b) => b.billedInCents)}
            dataset={{
              "Needs Approval": activeBills
                .filter((b) => needApprovalStatuses.includes(b.status.code))
                .sum((b) => b.billedInCents || 0),
              Processing: activeBills.filter((b) => isApprovedStatus(b.status.code)).sum((b) => b.billedInCents || 0),
              Paid: activeBills.filter((b) => b.status.code === BillStatus.Paid).sum((b) => b.billedInCents),
            }}
          />
        </div>
      </div>
    </div>
  );
}
