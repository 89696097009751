import {
  AutoSaveIndicator,
  BoundTextField,
  Button,
  Chip,
  Css,
  Icon,
  IconButton,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  Tooltip,
  useBreakpoint,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { addMonths } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FormGap, Price, SignatoryFooter } from "src/components";
import { IconListStyle, IconsList } from "src/components/IconsList";
import { QueryResultHandler } from "src/components/QueryResultHandler";
import { SignPandaDocButton } from "src/components/SignPandaDocButton";
import {
  ApproverStatus,
  BillStatus,
  InvoicePageDocument,
  InvoicePageFragment,
  InvoicePageQuery,
  InvoicePage_LienWaiversFragment,
  LienWaiverStatus,
  PandaDocStatus,
  SaveBillInput,
  useInvoicePageQuery,
  useInvoicePage_SaveApproverMutation,
  usePollLienWaiverQuery,
  useSaveInvoiceMutation,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks";
import { ObjectConfig, formatCentsToPrice, useFormState } from "src/utils";
import { DateOnly, formatMonthDayYear } from "src/utils/dates";
import { billStatusChipTypeMapper } from "src/utils/mappers";
import { PageHeader } from "../layout/PageHeader";
import { getChangeOrderUrl, getPurchaseOrderUrl, invoiceParam, invoicesPath } from "../routesDef";
import { InvoiceRequestChangesPane } from "./InvoicePage/InvoiceRequestChangesPane";
import { InvoiceTable } from "./InvoicePage/InvoiceTable";
import { getInvoicePrefix, isApprovedStatus, lienWaiverConditionMapper } from "./utils";

export function InvoicePage() {
  const { billId, projectId } = useParams<invoiceParam>();
  const query = useInvoicePageQuery({
    variables: {
      billId: billId ?? "",
      projectId: projectId ?? "",
    },
    skip: !billId || !projectId,
  });

  return QueryResultHandler<InvoicePageQuery>({
    result: query,
    render: InvoicePageView,
  });
}

const usePollLienWaivers = (bill: InvoicePageFragment) => {
  const [data, setData] = useState<InvoicePage_LienWaiversFragment[]>();
  const POLLING_TIMEOUT = 4000;

  const shouldPollLienWaivers = useMemo(
    () =>
      [BillStatus.PendingConditionalLienWaiver, BillStatus.PendingUnconditionalLienWaiver].includes(bill.status.code) &&
      bill.lienWaivers.find((lw) => [LienWaiverStatus.Draft, LienWaiverStatus.Uploaded].includes(lw.status.code)),
    [bill],
  );

  const query = usePollLienWaiverQuery({
    variables: { filter: { billId: [bill.id] } },
    skip: !shouldPollLienWaivers,
    pollInterval: 1000,
  });

  useEffect(() => {
    if (query.data && query.data.lienWaivers.find((lw) => LienWaiverStatus.Sent === lw.status.code)) {
      setData(query.data.lienWaivers ?? []);
      query.stopPolling();
    }
  }, [query]);

  if (shouldPollLienWaivers) {
    setTimeout(() => {
      query.stopPolling();
    }, POLLING_TIMEOUT);
  }

  return { data };
};

function InvoicePageView(props: InvoicePageQuery) {
  const { mdAndDown: mediumAndSmaller } = useBreakpoint();
  const { bill } = props;

  const currentUser = useCurrentUser();
  const [saveApprover] = useInvoicePage_SaveApproverMutation();
  const { openRightPane } = useRightPane();
  const { triggerNotice } = useSnackbar();
  const tid = useTestIds({});
  const [saveInvoice] = useSaveInvoiceMutation();

  const { data: lienWaiversFromPolling } = usePollLienWaivers(bill);

  const formState = useFormState({
    config: config,
    init: { input: bill, map: (b) => ({ referenceNumber: b?.referenceNumber }) },
    autoSave: async (state) => {
      const { referenceNumber } = state.changedValue;
      await saveInvoice({
        variables: { projectId: bill.project!.id, input: { id: bill.id, referenceNumber } },
      });
    },
  });

  const tpus = currentUser.tradePartnerUsers?.map((tpu) => tpu.id) ?? [];
  const currentUserApprover = bill.approval?.approvers.find((approver) => tpus.includes(approver.user.id));

  const scheduleTasks = bill.lineItems
    .flatMap((li) => li.scheduleTask)
    .compact()
    .uniqueByKey("id");
  const [type, text] = billStatusChipTypeMapper(bill.status.code);
  const approveLabel = bill.status.code === BillStatus.ChangesRequested ? "View Requested Changes" : "Request Changes";

  const [lienWaivers, setLienWaivers] = useState(
    bill.lienWaivers.filter((lw) => lw.pandaDoc?.canCreatePandaDocSession?.allowed),
  );

  useEffect(() => {
    if (lienWaiversFromPolling) {
      setLienWaivers(lienWaiversFromPolling.filter((lw) => lw.pandaDoc?.canCreatePandaDocSession?.allowed));
    }
  }, [lienWaiversFromPolling]);

  return (
    <div css={Css.bgGray100.h100.w100.fg1.$}>
      <PageHeader
        sticky
        xss={Css.bgWhite.mb0.$}
        breadcrumb={{
          label: (
            <div css={Css.df.fdr.px3.gap1.smMd.blue500.$}>
              <Icon icon="arrowLeft" inc={2} />
              All Invoices
            </div>
          ),
          href: invoicesPath,
        }}
        right={
          <div css={Css.df.fdr.aic.gap3.px3.smMd.if(mediumAndSmaller).gap0.$}>
            {lienWaivers
              .filter((lw) => lw.pandaDoc)
              .map(({ id, pandaDoc, condition }) => (
                <SignPandaDocButton
                  key={id}
                  label={`${pandaDoc!.status === PandaDocStatus.Completed ? "" : "Sign"} ${lienWaiverConditionMapper(
                    condition.code,
                  )}. LW`}
                  icon={pandaDoc!.status === PandaDocStatus.Completed ? "document" : undefined}
                  variant={pandaDoc!.status === PandaDocStatus.Completed ? "textSecondary" : "primary"}
                  pandaDocId={pandaDoc!.id}
                />
              ))}
            {!isApprovedStatus(bill.status.code) && (
              <>
                <Button
                  label={mediumAndSmaller ? "" : approveLabel}
                  tooltip={approveLabel}
                  disabled={disableApprovalReason(bill.status.code, currentUserApprover?.id)}
                  variant="tertiary"
                  onClick={() => openRightPane({ content: <InvoiceRequestChangesPane /> })}
                  icon="undoCircle"
                />
                <Button
                  variant="primary"
                  icon="checkCircle"
                  label="Approve"
                  disabled={disableApprovalReason(bill.status.code, currentUserApprover?.id)}
                  onClick={async () => {
                    const { data } = await saveApprover({
                      variables: { input: { id: currentUserApprover?.id ?? "", status: ApproverStatus.Approved } },
                      refetchQueries: [
                        { query: InvoicePageDocument, variables: { billId: bill.id, projectId: bill.project!.id } },
                      ],
                    });

                    if (data) {
                      triggerNotice({
                        message: `Invoice Approved! Your ${formatCentsToPrice(
                          bill.billedInCents,
                        )} invoice is being processed. Your invoice will be paid on or before:
                    ${formatMonthDayYear(new DateOnly(addMonths(new Date(), 1)))}
                    `,
                        icon: "success",
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        }
      />

      <ScrollableContent virtualized>
        <div css={Css.h100.$}>
          <RightPaneLayout>
            <div css={Css.mx4.p6.mt3.bgWhite.fg1.h100.$}>
              <PageHeader
                left={
                  <div css={Css.df.fdr.gap2.aic.if(mediumAndSmaller).fdc.aifs.gap0.$} {...tid.tradePartnerNumber}>
                    <div css={Css.xl2Sb.$}>
                      {getInvoicePrefix(bill)} #{bill.tradePartnerNumber}
                    </div>
                    <Chip type={type} text={text} compact />
                  </div>
                }
                right={
                  <>
                    {bill.documents?.first?.asset.downloadUrl && (
                      <IconButton
                        color={Palette.Blue500}
                        inc={3}
                        icon="download"
                        onClick={bill.documents.first.asset.downloadUrl}
                      />
                    )}
                  </>
                }
                xss={Css.bn.$}
              />

              {mediumAndSmaller ? (
                <div css={Css.df.fdc.jcsb.$}>
                  <div css={Css.my2.$}>
                    <InvoiceTotalAmountDue bill={bill} tid={tid} />
                  </div>
                  <InvoiceSummaryIconList bill={bill} />
                </div>
              ) : (
                <div css={Css.df.fdr.jcsb.aife.$}>
                  <InvoiceSummaryIconList bill={bill} />
                  <InvoiceTotalAmountDue bill={bill} tid={tid} />
                </div>
              )}
              <div css={Css.df.gap1.aic.$}>
                <Tooltip
                  title="Use the reference number field to store PO/Invoice numbers from your system"
                  delay={1000}
                >
                  <Icon icon="document" inc={2} />
                  <BoundTextField
                    compact
                    field={formState.referenceNumber}
                    label="Reference Number"
                    labelStyle="inline"
                  />
                </Tooltip>

                <AutoSaveIndicator />
              </div>

              <InvoiceTable lineItems={bill.lineItems} enableProductConfigPlan={bill.project.enableProductConfigPlan} />

              {scheduleTasks.nonEmpty && (
                <div css={Css.dg.gtc("3fr 3fr").py6.$}>
                  <FormGap />
                  <div>
                    <div css={Css.tiny.gray600.$}>Related Schedule Tasks</div>
                    {scheduleTasks.map((tsk) => (
                      <div css={Css.mt1.$} key={tsk?.id} {...tid.task}>
                        <div css={Css.xs.$}>{tsk.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <SignatoryFooter tradeContact={bill.tradePartner.contactsForProjectAndRoles.first} />
            </div>
          </RightPaneLayout>
        </div>
      </ScrollableContent>
    </div>
  );
}

interface InvoiceSummaryIconListProps {
  bill: InvoicePageQuery["bill"];
}

function InvoiceSummaryIconList({ bill }: InvoiceSummaryIconListProps) {
  return (
    <IconsList
      list={[
        {
          icon: "commitment",
          links: invoiceParentLinks(bill.parents, bill.project.id),
        },
        { icon: "house", value: bill.project.buildAddress.street1 },
        { icon: "floorPlan", value: bill.project.readyPlanConfig?.readyPlan?.displayName },
        {
          icon: "calendar",
          date: { label: "Issue date:", value: bill.sentDate },
        },
      ]}
      listStyle={IconListStyle.SimpleList}
    />
  );
}

function InvoiceTotalAmountDue({ bill, tid }: { bill: InvoicePageQuery["bill"]; tid: Record<string, object> }) {
  return (
    <div css={Css.df.fdc.gap1.$}>
      <div css={Css.tinySb.gray600.$}>Total amount due </div>
      <div css={Css.xl3Md.$} {...tid.total}>
        <Price valueInCents={bill.isTradePartnerCredit ? -bill.billedInCents : bill.billedInCents} />
      </div>
    </div>
  );
}

function invoiceParentLinks(parents: InvoicePageQuery["bill"]["parents"], projectId: string) {
  return parents.map((p) => {
    const isCo = p.__typename === "CommitmentChangeOrder";
    return {
      label: isCo ? `CO #${p.accountingNumber}` : `PO #${p.accountingNumber}`,
      href: isCo ? getChangeOrderUrl(projectId, p.commitment.id, p.id) : getPurchaseOrderUrl(projectId, p.id),
      openNewTab: true,
    };
  });
}

export function disableApprovalReason(status: BillStatus, approverId: string | undefined) {
  if (!approverId) return "You are not allowed to approve this invoice";
  if (isApprovedStatus(status)) return "You already approved this invoice";
  if (status === BillStatus.ChangesRequested) return "You already requested changes to this invoice";
  if (status === BillStatus.Paid) return "This invoice has been paid";
  if (status === BillStatus.Reversed) return "This invoice has been reversed";
  if (status === BillStatus.Cancelled) return "This invoice has been cancelled";
}

const config: ObjectConfig<Pick<SaveBillInput, "referenceNumber">> = {
  referenceNumber: { type: "value" },
};
