import {
  Chip,
  Css,
  DateRange,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  Pagination,
  ScrollableContent,
  column,
  emptyCell,
  selectColumn,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";

import { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { QueryResultHandler } from "src/components/QueryResultHandler";
import { dateCell, priceCell } from "src/components/gridTableCells";
import { useQueryStorage } from "src/components/useQueryStorage";
import {
  CommitmentOrder,
  CommitmentStatus,
  CommitmentsFilter,
  CurrentUserQuery,
  DateOperation,
  InputMaybe,
  Order,
  PurchaseOrdersPageQuery,
  PurchaseOrdersPage_ChangeOrderFragment,
  PurchaseOrdersPage_CommitmentFragment,
  PurchaseOrdersPage_ProjectsFragment,
  useCurrentUserQuery,
  usePurchaseOrdersPageQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { DateOnly, foldEnum, isDefined, pageSchema } from "src/utils";
import { CommitmentStatusName, commitmentStatusToChipTypeMapper } from "src/utils/mappers";
import { parseOrder, toOrder } from "src/utils/ordering";
import { isApprovedStatus } from "../invoices/utils";
import { getChangeOrderUrl, getPurchaseOrderUrl } from "../routesDef";
import { PurchaseOrdersSummary } from "./components/purchaseOrdersSummary/PurchaseOrdersSummary";
import { PurchaseOrdersFilter } from "./utils";

const initialStatus = [
  CommitmentStatus.Sent,
  CommitmentStatus.Signed,
  CommitmentStatus.PartiallySigned,
  CommitmentStatus.Voided,
];

function mapPageFiltersToCommitmentsFilters(
  queryStorageFilters: PurchaseOrdersFilter,
  projects?: PurchaseOrdersPage_ProjectsFragment[],
): CommitmentsFilter {
  const { releaseDateRange, signedDateRange, marketId, status, addressId: projectIds } = queryStorageFilters;

  const commitmentStatusMapped = status?.flatMap((name) =>
    foldEnum(name as CommitmentStatusName, {
      "Needs Signature": [
        CommitmentStatus.Draft,
        CommitmentStatus.Uploaded,
        CommitmentStatus.Sent,
        CommitmentStatus.PartiallySigned,
      ],
      Released: CommitmentStatus.Signed,
      Voided: CommitmentStatus.Voided,
    }),
  );

  return {
    ...(marketId?.nonEmpty ? { marketId } : {}),
    status: status?.nonEmpty ? commitmentStatusMapped : initialStatus,
    projectIds: projectIds?.nonEmpty ? projectIds : undefined,
    executionDate: signedDateRange?.value?.from
      ? {
          op: DateOperation.Between,
          value: new DateOnly(signedDateRange?.value?.from),
          value2: signedDateRange?.value?.to && new DateOnly(signedDateRange?.value?.to),
        }
      : undefined,
    sentDate: releaseDateRange?.value?.from
      ? {
          op: DateOperation.Between,
          value: new DateOnly(releaseDateRange?.value?.from),
          value2: releaseDateRange?.value?.to && new DateOnly(releaseDateRange?.value?.to),
        }
      : undefined,
  };
}

const PurchaseOrdersPageData = (
  queryStorageFilters: PurchaseOrdersFilter,
  setQueryStorageFilters: (filter: PurchaseOrdersFilter) => void,
) => {
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [order, setOrder] = useState<CommitmentOrder>({ accountingNumber: Order.Desc });

  const [filter, setFilter] = useState<CommitmentsFilter>(mapPageFiltersToCommitmentsFilters(queryStorageFilters));

  const query = usePurchaseOrdersPageQuery({
    variables: {
      filter: {
        ...filter,
      },
      order,
      page: pageSettings,
    },
  });

  return QueryResultHandler<PurchaseOrdersPageQuery>({
    result: query,
    render: (data) => (
      <PurchaseOrdersPageView
        {...data}
        pageSettings={pageSettings}
        setPageSettings={setPageSettings}
        setOrder={setOrder}
        order={order}
        filter={filter}
        setFilter={setFilter}
        queryStorageFilters={queryStorageFilters}
        setQueryStorageFilters={setQueryStorageFilters}
      />
    ),
  });
};

export function PurchaseOrdersPage() {
  const userQuery = useCurrentUserQuery();

  const { queryStorage: queryStorageFilters, setQueryStorage: setQueryStorageFilters } =
    useQueryStorage<PurchaseOrdersFilter>({
      storageKey: "purchaseOrdersPageFilter",
      initialQueryStorage: {
        ...purchaseFilterDefault,
      },
    });

  return QueryResultHandler<CurrentUserQuery>({
    result: userQuery,
    render: (data) => {
      const userMarkets =
        data.currentUser?.tradePartnerUsers
          .flatMap((tpu) => tpu.tradePartnerContact?.markets)
          .compact()
          .map((market) => market.id) ?? [];
      setQueryStorageFilters({ ...queryStorageFilters, marketId: userMarkets });
      return PurchaseOrdersPageData(queryStorageFilters, setQueryStorageFilters);
    },
  });
}

function PurchaseOrdersPageView(
  props: PurchaseOrdersPageQuery & {
    pageSettings: { offset: number; limit: number };
    setPageSettings: (state: { offset: number; limit: number }) => void;
    order: CommitmentOrder;
    setOrder: React.Dispatch<React.SetStateAction<CommitmentOrder>>;
    filter: CommitmentsFilter;
    setFilter: (filter: CommitmentsFilter) => void;
    queryStorageFilters: PurchaseOrdersFilter;
    setQueryStorageFilters: (filter: PurchaseOrdersFilter) => void;
  },
) {
  const {
    commitmentsPage,
    pageSettings,
    setPageSettings,
    setOrder,
    order,
    projects,
    currentUser,
    setFilter,
    queryStorageFilters,
    setQueryStorageFilters,
  } = props;

  const [searchFilter, setSearchFilter] = useState("");

  const history = useHistory();
  const createRowStyles = useCallback(
    () => {
      function openRowPage(row: GridDataRow<Row>) {
        if (row.kind === "commitment") {
          history.push(getPurchaseOrderUrl(row.data.project.id, row.id));
        }
        if (row.kind === "changeOrder") {
          history.push(getChangeOrderUrl(row.data.commitment.project.id, row.data.commitment.id, row.id));
        }
      }
      return {
        commitment: { onClick: openRowPage },
        changeOrder: { onClick: openRowPage },
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-react-projects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const availableMarkets = useMemo(
    () =>
      projects
        .map((p) => p.market)
        .uniqueByKey("id")
        .sortByKey("name"),
    [projects],
  );

  const tradePartners = useMemo(
    () => currentUser?.tradePartnerUsers?.flatMap((tpu) => tpu.tradePartner) ?? [],
    [currentUser],
  );

  const tid = useTestIds({});

  const handleFilterUpdate = useCallback(
    (filter: PurchaseOrdersFilter) => {
      console.log("log handleFilterUpdate", filter);
      setQueryStorageFilters(filter);
      setFilter(mapPageFiltersToCommitmentsFilters(filter, projects));
    },
    [projects, setFilter, setQueryStorageFilters],
  );

  return (
    <ScrollableContent virtualized>
      <div css={Css.bgGray100.h100.w100.fg1.py4.px2.bgGray100.ifSm.pt3.$}>
        <div css={Css.mxa.maxwPx(1280).$}>
          <PurchaseOrdersSummary
            filter={queryStorageFilters}
            setFilter={handleFilterUpdate}
            setSearchFilter={setSearchFilter}
            commitmentsPage={commitmentsPage}
            markets={availableMarkets}
            tradePartners={tradePartners}
            projects={projects}
          />
          <div css={Css.bgWhite.px4.pt1.pb4.$}>
            <div css={Css.df.fdr.my4.mb4.jcsb.ifSm.fdc.mt2.$}>
              <div css={Css.xl2Sb.ifSm.mb2.lgSb.$} {...tid.tableTitle}>
                Purchase Orders
              </div>
            </div>
            <div css={Css.oxa.$}>
              <ScrollableContent>
                <GridTable
                  as="table"
                  columns={createColumns()}
                  rows={createRows(commitmentsPage.commitments)}
                  stickyHeader
                  fallbackMessage={searchFilter ? "There are no POs matching your search" : "No rows found"}
                  style={{ bordered: false, allWhite: true }}
                  filter={searchFilter}
                  sorting={{
                    on: "server",
                    onSort: (key, direction) => setOrder(toOrder(key, direction)),
                    value: parseOrder(order),
                  }}
                  rowStyles={createRowStyles()}
                />
                <Pagination page={[pageSettings, setPageSettings]} totalCount={commitmentsPage.pageInfo.totalCount} />
              </ScrollableContent>
            </div>
          </div>
        </div>
      </div>
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type CommitmentRow = { kind: "commitment"; data: PurchaseOrdersPage_CommitmentFragment };
type ChangeOrderRow = { kind: "changeOrder"; data: PurchaseOrdersPage_ChangeOrderFragment };
type Row = HeaderRow | CommitmentRow | ChangeOrderRow;

function createRows(commitments: PurchaseOrdersPage_CommitmentFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "commitment" as const,
      id: c.id,
      data: c,
      children: c.changeOrders.map((co) => ({
        kind: "changeOrder" as const,
        id: co.id,
        data: co,
      })),
    })),
  ];
}

function createColumns(): GridColumn<Row>[] {
  return [
    selectColumn<Row>({ w: "32px", header: emptyCell }),
    column<Row>({
      id: "po#",
      header: "PO#",
      commitment: ({ accountingNumber }) => ({
        content: () => `#${accountingNumber}`,
        sortValue: accountingNumber,
      }),
      changeOrder: ({ accountingNumber }) => ({
        content: () => `CO#${accountingNumber}`,
        sortValue: accountingNumber,
      }),
      w: "70px",
    }),
    column<Row>({
      header: "Reference Number",
      commitment: ({ referenceNumber }) => referenceNumberCell(referenceNumber),
      changeOrder: ({ referenceNumber }) => referenceNumberCell(referenceNumber),
      w: "100px",
    }),
    column<Row>({
      header: "Status",
      commitment: (c) => {
        const [type, name] = commitmentStatusToChipTypeMapper(c.status);
        return { content: () => <Chip type={type} text={name || ""} />, value: c.status };
      },
      changeOrder: (co) => {
        const [type, name] = commitmentStatusToChipTypeMapper(co.status);
        return { content: () => <Chip type={type} text={name || ""} />, value: co.status };
      },
      w: "140px",
    }),
    column<Row>({
      header: "Address",
      commitment: ({ project }) => ({ content: project.buildAddress.street1, value: project.buildAddress.street1 }),
      changeOrder: ({ commitment }) => ({
        content: commitment.project.buildAddress.street1,
        value: commitment.project.buildAddress.street1,
      }),
      w: "160px",
    }),
    column<Row>({
      header: "Release date",
      id: "releasedOn",
      commitment: ({ sentDate, executionDate }) => dateCell(sentDate ?? executionDate),
      changeOrder: ({ sentDate, executionDate }) => dateCell(sentDate ?? executionDate),
      w: "170px",
    }),
    column<Row>({
      header: "Committed",
      commitment: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      changeOrder: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      w: "100px",
    }),
    column<Row>({
      header: "Ready to Pay",
      commitment: ({ bills }) => {
        // All approved, unpaid PO bills & sum the remaining balance
        const processingAmount = bills
          .filter((b) => isApprovedStatus(b.status.code) && !b.paidDate)
          .sum((b) => b.balanceInCents);
        return priceCell({ valueInCents: processingAmount });
      },
      changeOrder: ({ bills }) => {
        // All approved, unpaid PO bills & sum the remaining balance
        const processingAmount = bills
          .filter((b) => isApprovedStatus(b.status.code) && !b.paidDate)
          .sum((b) => b.balanceInCents);
        return priceCell({ valueInCents: processingAmount });
      },
      clientSideSort: false,
      w: "100px",
    }),
    column<Row>({
      header: "Paid",
      commitment: ({ paidInCents }) => priceCell({ valueInCents: paidInCents }),
      changeOrder: ({ paidInCents }) => priceCell({ valueInCents: paidInCents }),
      w: "100px",
    }),
    column<Row>({
      header: "Remaining",
      commitment: ({ unbilledInCents }) => priceCell({ valueInCents: unbilledInCents }),
      changeOrder: ({ unbilledInCents }) => priceCell({ valueInCents: unbilledInCents }),
      w: "100px",
    }),
    column<Row>({
      header: emptyCell,
      commitment: ({ id, project }) => (
        <Link to={getPurchaseOrderUrl(project.id, id)} css={Css.df.fdr.gap1.$}>
          View PO <Icon icon="arrowRight" inc={2} />
        </Link>
      ),
      changeOrder: ({ commitment, id }) => (
        <Link to={getChangeOrderUrl(commitment.project.id, commitment.id, id)} css={Css.df.fdr.gap1.$}>
          View PO <Icon icon="arrowRight" inc={2} />
        </Link>
      ),
      w: "100px",
      clientSideSort: false,
    }),
  ];
}

export function dateFilter(date: DateOnly | undefined | null, v: DateRange | InputMaybe<string[]>) {
  return (
    isDefined(v) &&
    !Array.isArray(v) &&
    isDefined(v.from) &&
    isDefined(v.to) &&
    !!date &&
    date >= new Date(v.from) &&
    date <= new Date(v.to)
  );
}

export const purchaseFilterDefault: PurchaseOrdersFilter = {
  addressId: [],
  status: [],
  marketId: [],
  releaseDateRange: undefined,
  signedDateRange: undefined,
  search: undefined,
};

function referenceNumberCell(referenceNumber?: string | undefined | null) {
  return referenceNumber
    ? {
        content: referenceNumber && <Chip text={referenceNumber} title={referenceNumber} type="dark" />,
        value: referenceNumber,
      }
    : emptyCell;
}
