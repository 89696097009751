import { css } from "@emotion/react";

const goodSansRegular = {
  fontFamily: "Good Sans",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
      local('Good Sans'),
      local('Good Sans-Regular'),
      url(/fonts/goodsans-regular.woff2) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const globalStyles = css`
  .darkScrollbar {
    /* Scrollbar styles for Firefox */
    scrollbar-width: 6px;
    scrollbar-color: #000 #fff;
  }

  /* Scrollbar styles for Chrome, Edge and Safari */
  .darkScrollbar::-webkit-scrollbar {
    width: 6px; /* For vertical scrollbar */
    height: 6px; /* For horizontal scrollbar */
    background-color: #fff;
  }

  .darkScrollbar::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .darkScrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #000000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  hr {
    margin: 0;
  }
`;
