import {
  AuthError,
  AuthResult,
  AuthViewProps,
  completeLogin,
  HomeboundUser,
  startLogin,
} from "@homebound/auth-components";
import { BoundTextField, Css, HbLoadingSpinner, Icon, Palette, useBreakpoint, useTestIds } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RouteProps, useHistory, useParams } from "react-router-dom";
import { HomeboundTradeLogo } from "src/components";
import { amplifyConfig, identifyHeap } from "src/context";
import { SignInRouteProps } from "src/Routes";
import { StringParam, useQueryParams } from "use-query-params";
import { GoogleLoginButton } from "./GoogleButton";

export interface SignInProps extends Pick<AuthViewProps, "setUser" | "setIsAuthenticated">, RouteProps {
  // For testing/mocking purposes
  completeLogin?: (config: any, token: string) => Promise<AuthResult<HomeboundUser>>;
  // For testing/mocking purposes
  startLogin?: (email: string) => Promise<AuthResult<any>>;
}

export function SignIn(props: SignInProps) {
  const [error, setError] = useState<AuthError>();
  const [handlingRequest, setHandlingRequest] = useState(false);
  const { linkCode } = useParams<SignInRouteProps>();
  const [{ signInError }] = useQueryParams({ signInError: StringParam });
  const tid = useTestIds({}, "signIn");
  const { sm } = useBreakpoint();

  const formState = useFormState({
    config: formConfig,
    init: {
      email: undefined,
      code: undefined,
    },
  });

  useEffect(
    () => {
      if (linkCode !== undefined) {
        // TODO: validate this eslint-ignore
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        submitCode(linkCode);
      } else if (signInError !== undefined) {
        setError({ code: "UserNotFoundException", message: "" });
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-react-projects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const history = useHistory();
  const enterCode = "/enterCode" === props.location?.pathname;

  async function handleLogin() {
    setHandlingRequest(true);
    const startLoginFunction = props.startLogin ?? startLogin;
    const { data, error } = await startLoginFunction(formState.email.value || "");
    setHandlingRequest(false);
    setError(error);
    data && history.push("/enterCode");
  }

  async function handleCode() {
    // TODO: validate this eslint-ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submitCode(formState.code.value || "");
  }

  async function submitCode(code: string) {
    setHandlingRequest(true);
    try {
      const completeLoginFunction = props.completeLogin ?? completeLogin;
      const { data: user, error } = await completeLoginFunction(amplifyConfig(), code);
      setHandlingRequest(false);

      if (user) {
        identifyHeap(user);
        props.setUser(user);
        props.setIsAuthenticated(true);
      } else {
        setError(error);
        history.push("/login");
      }
    } catch (e) {
      // TODO: auth-components/completeLogin should be updated to not throw exceptions
      setHandlingRequest(false);
      setError({ code: "NotAuthorizedException", message: "" });
      history.push("/login");
    }
  }

  function enterCodeForm() {
    return (
      <div css={Css.dg.gtc("1fr").gap2.$}>
        <Observer>
          {() => (
            <>
              <div css={Css.gray600.my2.tac.$}>
                <div css={Css.sm.$}>Email</div>
                <div css={Css.baseBd.my1.gray900.$}>{formState.email.value}</div>
                <div css={Css.sm.$}>
                  We just emailed you a temporary access code. <br /> Click the link in the email or enter the code
                  below:
                </div>
              </div>
              <BoundTextField label="Access Code" onEnter={handleCode} field={formState.code} labelStyle="above" />
              <div css={Css.mt1.tac.$}>
                <button
                  disabled={!formState.code.value || handlingRequest}
                  onClick={() => handleCode()}
                  css={Css.sm.w100.br4.bgBlue500.white.p1.onHover.bgBlue600.$}
                  {...tid.submitCode}
                >
                  Sign in
                </button>
                <button css={Css.sm.w100.br4.p1.mt1.blue500.onHover.bgGray100.$} onClick={() => history.push("/login")}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </Observer>
        {handlingRequest && <HbLoadingSpinner />}
      </div>
    );
  }

  function emailForm() {
    return (
      <div>
        <div css={Css.mt4.$}>
          <GoogleLoginButton />
        </div>

        <div css={Css.my2.$}>
          <div css={Css.sm.gray600.tac.my2.$}>or sign in with email</div>
          <BoundTextField label="Email" field={formState.email} onEnter={handleLogin} labelStyle="above" />
          {signInErrorMessage()}
        </div>

        <Observer>
          {() => (
            <button
              disabled={!formState.email.value || handlingRequest}
              onClick={() => handleLogin()}
              css={Css.br4.w100.sm.bgBlue500.white.p1.$}
              {...tid.submitEmail}
            >
              Continue
            </button>
          )}
        </Observer>

        {handlingRequest && <HbLoadingSpinner />}
      </div>
    );
  }

  function signInForm() {
    return (
      <div css={Css.df.fdc.w100.aic.mya.$} {...tid.form}>
        <div css={Css.baseSb.$}>Sign in to</div>
        <HomeboundTradeLogo width={136} height={24} color={Palette.Gray900} />
        <div css={Css.df.fdc.fg1.wPx(400).ifSm.wPx(344).mb1.$}>
          {enterCode && !error ? enterCodeForm() : emailForm()}
        </div>
      </div>
    );
  }

  function signInErrorMessage() {
    function friendlyMessage() {
      switch (error?.code) {
        case "UserNotFoundException":
          return "This email isn’t registered to a trade partner account. Reach out to your Homebound contact if the problem persists.";
        case "NotAuthorizedException":
          return "Incorrect access code";
        default:
          return "Uh oh! Something went wrong";
      }
    }

    return (
      error && (
        <div {...tid.error} css={Css.pb1.mt1.df.$}>
          <div css={Css.aic.mya.mr1.$}>
            <Icon icon="errorCircle" color={Palette.Red600} />
          </div>
          <div css={Css.sm.red600.$}>{friendlyMessage()}</div>
        </div>
      )
    );
  }

  return (
    <div css={Css.df.fg1.fdc.vh100.$} className="signIn">
      <div css={Css.df.fg1.fdr.$} {...tid.main}>
        {!sm && <div css={{ ...backgroundImageCss, ...Css.ifLg.absolute.$ }} />}
        {signInForm()}
      </div>
    </div>
  );
}

type FormInput = {
  email: string | undefined | null;
  code: string | undefined | null;
};

const formConfig: ObjectConfig<FormInput> = {
  email: { type: "value" },
  code: { type: "value" },
};

const backgroundImageCss = {
  background: "url(/images/login/login-page.jpg)",
  WebkitBackgroundSize: "cover",
  MozBackgroundSize: "cover",
  OBackgroundSize: "cover",
  backgroundSize: "cover",
  height: "100%",
  width: "320px",
};
