import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useBreakpoint } from "@homebound/beam";
import { Auth } from "aws-amplify";
import { useState } from "react";
import GoogleButton from "react-google-button";

export function GoogleLoginButton() {
  const [disabled, setDisabled] = useState(false);
  const { sm } = useBreakpoint();

  return (
    <GoogleButton
      type="light"
      style={{
        width: sm ? "344px" : "400px",
        color: "black",
        fontFamily: "inherit",
        fontSize: "14px",
        fontWeight: "600",
        paddingRight: "48px",
        height: "51px",
        border: "1px solid #3333",
        borderRadius: "4px",
        boxShadow: "none",
      }}
      disabled={disabled}
      onClick={() => {
        setDisabled(true);
        // Setting provider allows us to self-host the login button
        // TODO: validate this eslint-ignore
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      }}
    />
  );
}
