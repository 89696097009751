const numDecimalPlaces = 2;
export const centsToDollars = (cents: number) => cents / 100;
export const dollarsToCents = (dollars: number) => Math.round(dollars * 100);
export const roundCentsToDollars = (cents: number) => Math.round(cents / 100);
export const isNegative = (cents: number) => !!cents && cents < 0;

export function normalizeNumberString(value: string, allowNegative?: boolean): string {
  // First determine if the number is negative. Remove the leading `$` if any, then check for leading `-`
  const isNegative = value.replace(/^\$/, "").charAt(0) === "-";

  // Replace all non-digit characters except for the `.`, and then split into an array based on the '.'
  const values = value.replace(/[^\d.]/g, "").split(".");

  // The first part of the array is our whole number - shift that out.
  const wholeNumber = values.shift();
  // Get fractional values, if any, from remaining values array.
  const fraction = values.length ? `.${values.join("")}` : "";

  const normalizedValue = `${wholeNumber}${fraction}`;

  // Add negative sign back in if applicable
  if (allowNegative && isNegative) {
    return `-${normalizedValue}`;
  }

  return normalizedValue;
}

/** Common localization logic for formatting numbers to strings. */
export function formatNumberToString(val: number, trim?: boolean) {
  return isNaN(val) || val === 0
    ? "0"
    : val.toLocaleString(undefined, {
        minimumFractionDigits: trim ? 0 : numDecimalPlaces,
        maximumFractionDigits: numDecimalPlaces,
      });
}

/** Adds decimal places to a number and strips trailing zeros */
export function trimNumber(val: number): number {
  return isNaN(val) ? 0 : parseFloat(val.toFixed(numDecimalPlaces));
}

/** Calculates percentage value. Returns 0 if denominator is 0 */
export function calcPercent(numerator: number, denominator: number) {
  return !denominator ? 0 : (numerator / denominator) * 100;
}

export function calculateMarkupPercentage(price: number, cost: number): number {
  return cost === 0 ? 0 : ((price - cost) / cost) * 100;
}

export function formatCentsToPrice(
  valueInCents: number,
  dropZero: boolean = false,
  displayDirection: boolean = false,
  trim: boolean = false,
  rounded: boolean = false,
) {
  const isNegative = !!valueInCents && valueInCents < 0;
  const prefix = isNegative ? "-$" : displayDirection ? "+$" : "$";
  const roundedDollars = roundCentsToDollars(Math.abs(valueInCents));
  const formattedPrice =
    typeof valueInCents !== "number" || (dropZero && valueInCents === 0)
      ? "-"
      : `${prefix}${formatNumberToString(rounded ? roundedDollars : centsToDollars(Math.abs(valueInCents)), trim)}`;
  return formattedPrice;
}

// Format currency abbreviation for billions, millions or thousands
export function currencyAbbreviation(valueInCents: number) {
  const prefix = isNegative(valueInCents) ? "-$" : "$";
  const dollars = centsToDollars(Math.abs(valueInCents));
  return Math.abs(dollars) >= 1.0e9
    ? `${prefix}${(Math.abs(dollars) / 1.0e9).toFixed(2)}B` // $6.54B
    : Math.abs(dollars) >= 1.0e6
    ? `${prefix}${(Math.abs(dollars) / 1.0e6).toFixed(2)}M` // $3.40M
    : Math.abs(dollars) >= 1.0e3
    ? `${prefix}${(Math.abs(dollars) / 1.0e3).toFixed(2)}K` // $3.31K
    : `${prefix}${Math.abs(dollars)}`;
}
