import { useEffect } from "react";
import { getStage } from "src/context";

export function ZendeskWidget() {
  useEffect(() => {
    // Even qa doesn't have a zendesk token, so only use this in prod
    if (getStage() !== "prod") return;

    const script = document.createElement("script");
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=08022436-3914-41d8-93d0-ac3018438405";
    script.id = "ze-snippet";

    script.onload = () => {
      // Commands from https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      // @ts-ignore
      zE("webWidget", "hide");
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
}

export function openZendesk(name: string, email: string) {
  // @ts-ignore
  zE("webWidget", "show");
  // @ts-ignore
  zE("webWidget", "prefill", {
    name: {
      value: name,
      readOnly: false,
    },
    email: {
      value: email,
      readOnly: false,
    },
  });
  // @ts-ignore
  zE("webWidget", "open");
}
