import { Avatar, Css, useTestIds } from "@homebound/beam";
import { ProjectRole, ProjectTeamMemberFragment } from "src/generated/graphql-types";

/**
 * User List that splits user avatar & contact details (name, role, email) into seperate columns
 */
interface UserListProps {
  users: ProjectTeamMemberFragment[];
  title?: string;
}
export function UserList(props: UserListProps) {
  const { users, title } = props;
  const visibleRoles = [
    ProjectRole.Superintendent,
    ProjectRole.ApAccountant,
    ProjectRole.PurchasingManager,
    ProjectRole.PurchasingOne, // TODO: Verify if same as purchasing agent (backup contact for lead purchaser)
  ];
  const tid = useTestIds({}, "userList");
  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.smMd.mb2.mt3.$} {...tid.title}>
        {title}
      </div>
      {users
        .filter((u) => visibleRoles.includes(u.role.code))
        .map((u, i) => (
          <div css={Css.df.fdr.aic.gap3.my1.$} key={`${u.user?.id}${u.role.code}`}>
            <Avatar src={u.user?.avatar} />
            <div css={Css.df.fdc.$}>
              <div css={Css.smMd.$} {...tid.name}>
                {u.user?.name}
              </div>
              <div css={Css.xsMd.df.fdr.gray600.$}>
                <span {...tid.role}>{u.role.name}</span>
              </div>
              {u.user?.email && (
                <a href={`mailto:${roleBasedEmail(u)}`} css={Css.xsMd.$} {...tid.email}>
                  {roleBasedEmail(u)}
                </a>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

function roleBasedEmail(u: ProjectTeamMemberFragment): string {
  switch (u.role.code) {
    case ProjectRole.ApAccountant:
      return "talktoap@homebound.com";

    default:
      return u.user?.email ?? "";
  }
}
