import {
  AutoSaveIndicator,
  BoundTextField,
  Chip,
  Css,
  Icon,
  IconButton,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  Tooltip,
  useBreakpoint,
  useRightPane,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Price, SignatoryFooter } from "src/components";
import { IconListStyle, IconsList } from "src/components/IconsList";
import { QueryResultHandler } from "src/components/QueryResultHandler";
import { SignPandaDocButton } from "src/components/SignPandaDocButton";
import {
  CommitmentLike,
  PurchaseOrderPageQuery,
  usePurchaseOrderPageQuery,
  useSaveCommitmentChangeOrderMutation,
  useSaveCommitmentMutation,
} from "src/generated/graphql-types";
import { ObjectConfig, isDefined, useFormState } from "src/utils";
import { commitmentStatusToChipTypeMapper } from "src/utils/mappers";
import { PageHeader } from "../layout/PageHeader";
import { disableBasedOnPotentialOperation } from "../layout/PotentialOperationsUtil";
import { purchaseOrderParam, purchaseOrdersPath } from "../routesDef";
import { PurchaseOrderDetailPane } from "./components/detailPane/PurchaseOrderDetailPane";
import { CommitmentLikeType } from "./components/detailPane/utils";
import { PurchaseOrderTemplatesTable } from "./components/purchaseOrderPage/PurchaseOrderTemplatesTable";

export function PurchaseOrderPage() {
  const { purchaseOrderId, changeOrderId, projectId } = useParams<purchaseOrderParam>();
  const query = usePurchaseOrderPageQuery({
    variables: {
      commitmentId: purchaseOrderId ?? "",
      changeOrderId: changeOrderId ?? "",
      projectId: projectId ?? "",
      // Use a skip directive inline to prevent querying a commitment/cco depending on which page we've requested
      // Allows us to also handle the request for one or the other in the same query
      skipCommitment: !!changeOrderId,
      skipChangeOrder: !changeOrderId,
    },
  });

  return QueryResultHandler<PurchaseOrderPageQuery>({
    result: query,
    render: PurchaseOrderPageView,
  });
}

function PurchaseOrderPageView(props: PurchaseOrderPageQuery) {
  const { commitment, commitmentChangeOrder } = props;
  const commitmentLike = commitment ?? commitmentChangeOrder;

  const [commitmentPaneId, setPaneId] = useState<string | undefined>(commitmentLike?.id);
  const { openRightPane, closeRightPane } = useRightPane();
  const tid = useTestIds({});
  const { sm, mdAndDown } = useBreakpoint();

  const isChangeOrder = isDefined(commitmentChangeOrder);
  const coSentDate = commitmentChangeOrder?.sentDate ?? commitmentChangeOrder?.executionDate;
  const sentDate = coSentDate ?? commitment?.sentDate ?? commitment?.executionDate;
  const project = commitment?.project ?? commitmentChangeOrder?.commitment.project;

  const smallOrRightPaneOpen = sm || (mdAndDown && isDefined(commitmentPaneId));

  const [saveCommitment] = useSaveCommitmentMutation();
  const [saveCommitmentChangeOrder] = useSaveCommitmentChangeOrderMutation();

  const formState = useFormState({
    config: config,
    init: { input: commitmentLike, map: (po) => ({ referenceNumber: po?.referenceNumber }) },
    autoSave: async (state) => {
      const { referenceNumber } = state.changedValue;
      const save = commitment ? saveCommitment : saveCommitmentChangeOrder;
      await save({
        variables: { projectId: project!.id, input: { id: commitmentPaneId, referenceNumber } },
      });
    },
  });

  const handleClose = useCallback(() => {
    setPaneId(undefined);
    closeRightPane();
  }, [closeRightPane]);

  useEffect(() => {
    isDefined(commitmentPaneId) &&
      openRightPane({
        content: (
          <PurchaseOrderDetailPane handleClose={handleClose} commitmentLike={commitmentLike as CommitmentLikeType} />
        ),
      });
  }, [commitmentLike, commitmentPaneId, openRightPane, handleClose]);

  if (!commitmentLike) return <div>Error loading Commitment</div>;
  const pandaDoc = commitmentLike.pandaDoc;
  const [type, name] = commitmentStatusToChipTypeMapper(commitmentLike.status);

  return (
    <div css={Css.bgGray100.h100.w100.fg1.$}>
      <PageHeader
        sticky
        xss={Css.bgWhite.mb0.$}
        breadcrumb={{
          label: (
            <div css={Css.df.fdr.px3.gap1.smMd.blue500.$}>
              <Icon icon="arrowLeft" inc={2} />
              All Purchase Orders
            </div>
          ),
          href: purchaseOrdersPath,
        }}
        right={
          <div css={Css.df.fdr.gap3.aic.px3.smMd.$}>
            {isDefined(pandaDoc) && (
              <SignPandaDocButton
                label={name === "Needs Signature" ? "Sign PO" : "View PO"}
                disabled={disableBasedOnPotentialOperation(pandaDoc.canCreatePandaDocSession!)}
                pandaDocId={pandaDoc.id}
              />
            )}
          </div>
        }
      />

      <ScrollableContent virtualized>
        <div css={Css.h100.$}>
          {/* paneWidth can only be set to a number, so for now we can only make it smaller to fit mobile screens */}
          <RightPaneLayout paneWidth={sm ? 350 : undefined}>
            <div css={Css.mx4.p6.mt3.bgWhite.fg1.h100.ifSm.mx2.$}>
              <div css={Css.df.fdr.mb2.if(smallOrRightPaneOpen).fdc.$}>
                <div css={Css.df.fdr.gap2.if(smallOrRightPaneOpen).gap0.fdc.mb2.$} {...tid.accountNumber}>
                  {isChangeOrder ? (
                    <div css={Css.xl2Sb.$}>Change Order #{commitmentLike.accountingNumber}</div>
                  ) : (
                    <div css={Css.xl2Sb.$}>Purchase Order #{commitmentLike.accountingNumber}</div>
                  )}
                  <div css={Css.h2.mtPx(2).$}>
                    <Chip type={type} text={name} compact />
                  </div>
                </div>
                <div css={Css.df.fdr.gap1.mla.aic.if(smallOrRightPaneOpen).ml0.mt1.$}>
                  {commitmentLike.document?.asset.downloadUrl && (
                    <IconButton
                      color={Palette.Blue500}
                      inc={3}
                      icon="download"
                      onClick={commitmentLike.document?.asset.downloadUrl}
                    />
                  )}
                  <div css={Css.tinySb.blue500.$}>
                    {!commitmentPaneId ? (
                      <div onClick={() => setPaneId(commitmentLike.id)}>Show Details</div>
                    ) : (
                      <div onClick={handleClose}> Hide Details</div>
                    )}
                  </div>
                </div>
              </div>

              <div css={Css.df.fdr.jcsb.if(smallOrRightPaneOpen).fdcr.$}>
                <div>
                  <IconsList
                    list={[
                      { icon: "house", value: project?.buildAddress.street1 },
                      { icon: "floorPlan", value: project?.readyPlanConfig?.readyPlan?.displayName },
                      { icon: "calendar", date: { label: "Issue date:", value: sentDate } },
                    ]}
                    listStyle={IconListStyle.SimpleList}
                  />
                  <div css={Css.df.gap1.aic.$}>
                    <Tooltip
                      title="Use the reference number field to store PO/Invoice numbers from your system"
                      delay={1000}
                    >
                      <Icon icon="document" inc={2} />
                      <BoundTextField
                        compact
                        field={formState.referenceNumber}
                        label="Reference Number"
                        labelStyle="inline"
                      />
                    </Tooltip>

                    <AutoSaveIndicator />
                  </div>
                </div>

                <div css={Css.df.fdc.aife.gap1.if(smallOrRightPaneOpen).ais.mb3.$}>
                  <div css={Css.tinySb.gray600.$}>Total amount due</div>
                  <div css={Css.xl3Md.$} {...tid.total}>
                    <Price valueInCents={commitmentLike.committedInCents} />
                  </div>
                </div>
              </div>

              <div css={Css.oa.$}>
                <PurchaseOrderTemplatesTable commitmentlike={commitmentLike} />
              </div>
              <SignatoryFooter tradeContact={commitmentLike.tradePartner?.signatoryForProject} />
            </div>
          </RightPaneLayout>
        </div>
      </ScrollableContent>
    </div>
  );
}

const config: ObjectConfig<Pick<CommitmentLike, "referenceNumber">> = {
  referenceNumber: { type: "value" },
};
