import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ReactNode, useCallback } from "react";

export type ConfirmationModalProps = {
  confirmationMessage: ReactNode | string;
  onConfirmAction: () => void;
  title: string;
  label: string;
  danger?: boolean;
  disabled?: boolean;
};

export function ConfirmationModal(props: ConfirmationModalProps) {
  const { confirmationMessage, onConfirmAction, title, label, danger, disabled } = props;
  const { closeModal } = useModal();

  const onConfirmClick = useCallback(async () => {
    await onConfirmAction();
    closeModal();
  }, [onConfirmAction, closeModal]);

  const renderBody = () => {
    if (typeof confirmationMessage === "string") {
      return (
        <>
          <div>
            <p css={Css.baseMd.gray900.mb2.$}>{confirmationMessage}</p>
          </div>
        </>
      );
    } else {
      return confirmationMessage;
    }
  };

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{renderBody()}</ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button disabled={disabled} label={label} onClick={onConfirmClick} variant={!!danger ? "danger" : "primary"} />
      </ModalFooter>
    </>
  );
}
