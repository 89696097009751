import {
  Button,
  Css,
  DateRangeField,
  FieldGroup,
  FormLines,
  HasIdAndName,
  MultiSelectField,
  useTestIds,
} from "@homebound/beam";
import { useState } from "react";
import { DateOperation } from "src/generated/graphql-types";
import { PurchaseOrdersFilter } from "../../utils";

interface PurchaseOrderFilterModalProps {
  filterOptions?: {
    status: HasIdAndName[];
    projects: HasIdAndName[];
    markets: HasIdAndName[];
  };
  filter: PurchaseOrdersFilter;
  setFilter: (filter: PurchaseOrdersFilter) => void;
  close: () => void;
}

export function PurchaseOrderFilterModal({
  filterOptions = { status: [], projects: [], markets: [] },
  filter,
  setFilter,
  close,
}: PurchaseOrderFilterModalProps) {
  const tid = useTestIds({});
  const [modalFilter, setModalFilter] = useState<PurchaseOrdersFilter>(filter);

  const onSubmit = () => {
    setFilter(modalFilter);
    close();
  };

  const { status, projects, markets } = filterOptions;

  return (
    <div {...tid.modal}>
      <FormLines>
        <FieldGroup>
          <MultiSelectField
            onSelect={(val) =>
              setModalFilter((prevState) => ({
                ...prevState,
                addressId: val.nonEmpty ? val : [],
              }))
            }
            label="Address"
            options={projects}
            values={modalFilter.addressId}
          />
          <MultiSelectField
            onSelect={(val) => {
              return setModalFilter((prevState) => ({
                ...prevState,
                status: val.nonEmpty ? val : [],
              }));
            }}
            label="Status"
            options={status}
            values={modalFilter.status}
          />
          <MultiSelectField
            onSelect={(val) => {
              return setModalFilter((prevState) => ({
                ...prevState,
                marketId: val.nonEmpty ? val : undefined,
              }));
            }}
            label="Market"
            options={markets}
            values={modalFilter.marketId ?? []}
          />
        </FieldGroup>
        <FieldGroup>
          <DateRangeField
            value={modalFilter.releaseDateRange?.value}
            onChange={(val) => {
              val &&
                setModalFilter((prevState) => ({
                  ...prevState,
                  releaseDateRange: {
                    value: { to: val.to, from: val.from },
                    op: DateOperation.Between,
                  },
                }));
            }}
            label="Release date range"
            hideCalendarIcon={true}
            placeholder="Release date range"
          />
          <DateRangeField
            value={modalFilter.signedDateRange?.value}
            onChange={(val) => {
              val &&
                setModalFilter((prevState) => ({
                  ...prevState,
                  signedDateRange: {
                    value: { to: val.to, from: val.from },
                    op: DateOperation.Between,
                  },
                }));
            }}
            label="Signed date range"
            hideCalendarIcon={true}
            placeholder="Signed date range"
          />
        </FieldGroup>
      </FormLines>
      <div css={Css.df.gap1.jcfe.mtPx(80).$}>
        <Button variant="tertiary" label="Cancel" onClick={close} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </div>
  );
}
