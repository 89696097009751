import { Accordion, BoundSelectField, Css, useComputed } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useEffect, useMemo } from "react";
import {
  ContactsTabTradePartnerContactFragment,
  ContactsTabTradePartnerMarketFragment,
  ContactsTabTradePartnerMarketRolesFragment,
  TradePartnerMarketRole,
  useSaveTradePartnerMarketContactMutation,
  useTradePartnerContactsRolesPanelMetadataQuery,
} from "src/generated/graphql-types";
import { groupBy } from "src/utils";

export interface TradePartnerContactsRolesProps {
  tradePartnerId: string;
  roles: ContactsTabTradePartnerMarketRolesFragment[];
  contacts: ContactsTabTradePartnerContactFragment[];
  markets: ContactsTabTradePartnerMarketFragment[];
}

export function TradePartnerContactsRolesPanel({
  tradePartnerId,
  roles,
  contacts,
  markets,
}: TradePartnerContactsRolesProps) {
  const { data } = useTradePartnerContactsRolesPanelMetadataQuery();
  const { tradePartnerMarketRoles } = data ?? { tradePartnerMarketRoles: [] };
  const [saveTradePartnerMarketRole] = useSaveTradePartnerMarketContactMutation();

  const rolesByMarket: RoleByMarketType[] = useMemo(
    () =>
      markets
        .map((market) => {
          const marketInfo = { tradePartnerId, marketId: market.id, marketName: market.name };
          const getContactByRole = (role: TradePartnerMarketRole) =>
            roles.find((r) => r.market.id === market.id && r.role.code === role);
          // Get the primary, signatoy and bill approver contacts for this market
          return tradePartnerMarketRoles.map((tpmr) => {
            const contact = getContactByRole(tpmr.code);
            return {
              ...marketInfo,
              tpContactRole: tpmr.code,
              tpContactRoleName: tpmr.name,
              tpContactId: contact?.contact.id,
              tpmcId: contact?.id,
            };
          });
        })
        .flat(),
    [markets, roles, tradePartnerId, tradePartnerMarketRoles],
  );

  const formState = useFormState({
    config: formConfig,
    init: { input: rolesByMarket, map: mapToForm },
  });

  const groupedByMarket = useComputed(
    () => groupBy(formState.rolesByMarket.rows, ({ marketName }) => marketName.value),
    [formState],
  );

  useEffect(() => {
    formState.set({ rolesByMarket });
  }, [formState, rolesByMarket]);

  return (
    <section css={Css.wPx(392).br4.p2.pt1.pb3.ifMdAndDown.px0.w100.$}>
      <div css={Css.lgBd.pb2.$}>Role by Market</div>
      <div css={Css.bgWhite.p2.br8.boxShadow("0 0 24px 0 rgba(53, 53, 53, 0.08)").$}>
        {Object.entries(groupedByMarket).map(([marketName, roles], index) => (
          <Accordion key={marketName} title={marketName} topBorder={index !== 0}>
            {roles.map((r) => {
              const role = r.tpContactRoleName.value.replace("Bill", "Invoice");
              return (
                <div key={`${r.marketId.value}-${r.tpContactRole.value}`} css={Css.pb2.$}>
                  <BoundSelectField
                    field={r.tpContactId}
                    label={role}
                    placeholder={`Select a ${role} contact`}
                    options={contacts}
                    onSelect={(tpContactId) => {
                      // Save the selected trade partner contact
                      if (tpContactId) {
                        // TODO: validate this eslint-ignore
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        saveTradePartnerMarketRole({
                          variables: {
                            input: {
                              contactId: tpContactId,
                              role: r.tpContactRole.value,
                              marketId: r.marketId.value,
                              tradePartnerId,
                            },
                          },
                        });
                      }
                    }}
                  />
                </div>
              );
            })}
          </Accordion>
        ))}
      </div>
    </section>
  );
}

type RoleByMarketType = {
  tpContactId: string | undefined | null;
  tpContactRole: TradePartnerMarketRole;
  tpContactRoleName: string;
  marketId: string;
  marketName: string;
  tpmcId: string | undefined;
};

interface FormValue {
  rolesByMarket: RoleByMarketType[];
}

const formConfig: ObjectConfig<FormValue> = {
  rolesByMarket: {
    type: "list",
    config: {
      tpContactId: { type: "value" },
      tpContactRole: { type: "value" },
      tpContactRoleName: { type: "value" },
      marketId: { type: "value" },
      marketName: { type: "value" },
      tpmcId: { type: "value" },
    },
  },
};

function mapToForm(rolesByMarket: RoleByMarketType[]) {
  return {
    rolesByMarket: rolesByMarket.map((r) => ({
      tpContactId: r.tpContactId,
      tpContactRole: r.tpContactRole,
      tpContactRoleName: r.tpContactRoleName,
      marketId: r.marketId,
      marketName: r.marketName,
      tpmcId: r.tpmcId,
    })),
  };
}
