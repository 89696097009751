import { Accordion, Css, IconKey, useTestIds } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { FormattedDate, QueryResultHandler } from "src/components";
import { IconListStyle, IconsList } from "src/components/IconsList";
import { Section } from "src/components/pdfs/Section";
import {
  PurchaseOrderDetailPane_SpecsTabQuery,
  usePurchaseOrderDetailPane_SpecsTabQuery,
} from "src/generated/graphql-types";
import { purchaseOrderParam } from "src/routes/routesDef";
import { isDefined } from "src/utils";
import { OptionTypes } from "./utils";

export function PurchaseOrderSpecsTab() {
  const { purchaseOrderId, changeOrderId } = useParams<purchaseOrderParam>();
  const query = usePurchaseOrderDetailPane_SpecsTabQuery({
    variables: {
      commitmentId: purchaseOrderId ?? "",
      changeOrderId: changeOrderId ?? "",
      skipCommitment: !purchaseOrderId,
      skipChangeOrder: !changeOrderId,
    },
  });

  return QueryResultHandler<PurchaseOrderDetailPane_SpecsTabQuery>({
    result: query,
    render: PurchaseOrdersSpecsTabView,
  });
}

function PurchaseOrdersSpecsTabView(props: PurchaseOrderDetailPane_SpecsTabQuery) {
  const { commitment, commitmentChangeOrder } = props;
  const tid = useTestIds({});

  const commitmentLike = commitment ?? commitmentChangeOrder;
  const isChangeOrder = isDefined(commitmentChangeOrder);
  const { lineItems = [] } = commitmentLike ?? {};
  const { contractExhibits, contractExclusions, project } =
    (!isChangeOrder ? commitment : commitmentChangeOrder?.commitment) ?? {};
  const readyPlanConfig = project?.readyPlanConfig;

  const groupedRpos = (readyPlanConfig?.options.flatMap((o) => o.readyPlanOption).compact() || []).groupBy(
    (rpo) => rpo.globalOption.type.name,
  );

  const tasks = lineItems
    .flatMap((li) => li.projectItem.task)
    .compact()
    .uniqueByKey("id");

  const floorPlanOptions = (groupedRpos[OptionTypes.FloorPlan] || []).map((rpo) => rpo.displayName);

  return (
    <div>
      <Accordion title="Configuration Details" bottomBorder topBorder={false} defaultExpanded>
        {!isDefined(readyPlanConfig?.id) ? (
          <EmptyAccordianMessage message="No configurations available" testId="emptySpecs" />
        ) : (
          <IconsList
            list={[
              { icon: "floorPlan", iconLabel: "Plan", value: readyPlanConfig?.readyPlan?.displayName },
              {
                icon: "floorPlan",
                iconLabel: "Plan options",
                value: floorPlanOptions,
                limitList: floorPlanOptions.length > 2,
              },
              ...[OptionTypes.ExteriorScheme, OptionTypes.AddOn, OptionTypes.Interior].map((type) => ({
                icon: GLOBAL_ICON_CONFIG[type]?.icon,
                iconLabel: GLOBAL_ICON_CONFIG[type]?.iconLabel,
                value: (groupedRpos[type] || []).map((rpo) => rpo.displayName),
              })),
            ]}
            listStyle={IconListStyle.DetailList}
          />
        )}
      </Accordion>

      <Accordion title="Schedule" defaultExpanded>
        {tasks.isEmpty ? (
          <EmptyAccordianMessage message="No tasks associated to the purchase order" testId="emptyTasks" />
        ) : (
          tasks.map((t) => (
            <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={t.id}>
              <div css={Css.dg.gtc("2fr 1fr").cg1.rg1.tiny.bgGray100.p2.asfe.br8.$}>
                <div css={Css.df.wbbw.$} {...tid.task}>
                  {t.name}
                </div>

                <div css={Css.df.fdc.$}>
                  <div>Est.Start - End date</div>
                  <div css={Css.df.fdr.tinySb.$} {...tid.taskStartEnd}>
                    <FormattedDate date={t.startDate || undefined} /> -
                    <FormattedDate date={t.completedAt || undefined} />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </Accordion>

      <Accordion title="Drawings and Exhibits" defaultExpanded>
        {contractExhibits ? (
          <Section title="" html={contractExhibits} />
        ) : (
          <EmptyAccordianMessage message="No drawings and exhibits available" testId="emptyExhibits" />
        )}
      </Accordion>

      <Accordion title="Exclusions" defaultExpanded>
        {contractExclusions ? (
          <Section title="" html={contractExclusions} />
        ) : (
          <div {...tid.emptyExclusions} css={Css.xsSb.$}>
            No exclusions available
          </div>
        )}
      </Accordion>
    </div>
  );
}

const GLOBAL_ICON_CONFIG: Record<string, { iconLabel: string; icon: IconKey }> = {
  [OptionTypes.ExteriorScheme]: { iconLabel: "Exterior options", icon: "leaf" },
  [OptionTypes.Interior]: { iconLabel: "Interior options", icon: "chair" },
  [OptionTypes.AddOn]: { iconLabel: "Add-ons", icon: "bolt" },
};

function EmptyAccordianMessage(props: { message: string; testId: string }) {
  const { testId, message } = props;
  const tid = useTestIds({});
  return (
    <div {...tid[`${testId}`]} css={Css.xsSb.$}>
      {message}
    </div>
  );
}
