// organize-imports-ignore
import "src/globalThis";
import { ApolloProvider } from "@apollo/client";
import { CssReset } from "@homebound/beam";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { App } from "./App";
import { amplifyConfig, createApolloClient, getStage } from "./context";
import { enableAnalytics } from "./utils/analytics";
import { configure } from "mobx";
import { createRoot } from "react-dom/client";
import { Global } from "@emotion/react";
import { globalStyles } from "./theme/emotionTheme";
import { configureAuth } from "@homebound/auth-components";

const stage = getStage();
const apolloClient = createApolloClient(stage);
configureAuth(amplifyConfig(stage), {
  // auth-components expects us to use /signIn, but we use the non-standard /login path
  signInFailureHandler: (errorMessage) => {
    const params = new URLSearchParams();
    params.append("signInError", errorMessage);
    window.location.assign("/login?" + params);
  },
});
void enableAnalytics(stage);
configure({ enforceActions: "never" });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Global styles={globalStyles} />
        <CssReset />
        <App />
      </QueryParamProvider>
    </Router>
  </ApolloProvider>,
);
