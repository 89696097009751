import { Css, Icon, Palette, useTestIds } from "@homebound/beam";
import { isNegative } from "src/utils";
import { FormGap } from "./FormGap";

/** Summary metric that displays metric data as a title, and renders optional delta value*/
export function SummaryMetric({ title, metric, delta }: { title: string; metric: string; delta?: number }) {
  const tid = useTestIds({}, "summaryMetric");
  return (
    <div css={Css.dg.gtc("1fr").gap1.$} {...tid[`${title.replace(/ /g, "_")}`]}>
      <div css={Css.gray600.xs.$}>{title}</div>
      <div css={Css.xl2Sb.ifMdAndDown.lgSb.$}>{metric}</div>
      {delta ? <DeltaLabel delta={delta} /> : <FormGap />}
    </div>
  );
}

export function DeltaLabel({ delta }: { delta: number }) {
  const isPositive = !isNegative(delta);
  const tid = useTestIds({}, "summaryMetric");

  return (
    <div css={Css.df.fdr.tiny.$}>
      <Icon
        icon={isPositive ? "triangleUp" : "triangleDown"}
        color={isPositive ? Palette.Green800 : Palette.Red600}
        inc={2}
      />
      <div {...tid.delta} css={Css.if(isPositive).green800.else.red600.$}>
        {Math.abs(delta)}
      </div>
      &nbsp; vs last week
    </div>
  );
}
