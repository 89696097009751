import { Button, ButtonModal, Css, HasIdAndName, useBreakpoint, useTestIds } from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { SearchBox } from "src/components";
import { FormattedDate } from "src/components/FormattedDate";
import { PieChart } from "src/components/PieChart";
import { SummaryMetric } from "src/components/SummaryMetric";
import {
  CommitmentStatus,
  PurchaseOrdersPageQuery,
  PurchaseOrdersPage_TradePartnerFragment,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { commitmentStatusToChipTypeMapper, pluralize } from "src/utils";
import { PurchaseOrdersFilter } from "../../utils";
import { PurchaseOrderFilterModal } from "../filterModal/PurchaseOrderFilterModal";

export type PurchaseOrdersSummaryProps = PurchaseOrdersPageQuery & {
  filter: PurchaseOrdersFilter;
  setFilter: (filter: PurchaseOrdersFilter) => void;
  setSearchFilter: (value: string) => void;
  markets: HasIdAndName[];
  tradePartners: PurchaseOrdersPage_TradePartnerFragment[];
};

const statusOptions = [
  CommitmentStatus.Sent,
  CommitmentStatus.Signed,
  CommitmentStatus.PartiallySigned,
  CommitmentStatus.Voided,
];

export function PurchaseOrdersSummary(props: PurchaseOrdersSummaryProps) {
  const { tradePartners, markets, filter, setFilter, setSearchFilter, projects } = props;
  const user = useCurrentUser();
  const { sm, mdAndDown } = useBreakpoint();
  const tid = useTestIds({}, "posummary");

  // Get totals and counts for all trade partners
  const activeTasksCount = tradePartners.sum((tp) => tp.activeTasksCount);
  const activeProjectsCount = tradePartners.sum((tp) => tp.activeProjectsCount);
  const totalCommittedInCents = tradePartners.sum((tp) => tp.totalCommittedInCents);
  const totalBilledInCents = tradePartners.sum((tp) => tp.totalBilledInCents);
  const totalPaidInCents = tradePartners.sum((tp) => tp.totalPaidInCents);
  const totalCommitmentCountThisWeek = tradePartners.sum((tp) => tp.commitmentCountThisWeek);
  const totalCommitmentCountLastWeek = tradePartners.sum((tp) => tp.commitmentCountLastWeek);

  const filterOptions = useMemo(
    () => ({
      projects: projects
        .map((p) => ({ id: p.id, name: p.buildAddress.street1 }))
        .sortByKey("name")
        .uniqueByKey("name"),

      status: statusOptions
        .map((s) => commitmentStatusToChipTypeMapper(s)[1])
        .map((status) => ({ id: status, name: status }))
        .uniqueByKey("name"),
      markets,
    }),
    [markets, projects],
  );

  const showPoFilterModal = useCallback(
    (close: VoidFunction) => {
      return (
        <PurchaseOrderFilterModal filterOptions={filterOptions} filter={filter} setFilter={setFilter} close={close} />
      );
    },
    [filterOptions, filter, setFilter],
  );
  const filterButtonTrigger = useMemo(() => ({ label: "Filter" }), []);

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.df.fdr.jcsb.$}>
        <div css={Css.smMd.df.fdr.$} {...tid.greeting}>
          {user.name && `Hi ${user?.name}! Today is`}
          &nbsp;
          <FormattedDate date={new Date()} dateFormatStyle="xshort" />
        </div>
        <div css={Css.df.aic.gap1.jcfe.$}>
          <SearchBox onSearch={setSearchFilter} />
          <ButtonModal content={showPoFilterModal} trigger={filterButtonTrigger} />
          {!Object.values(filter)
            .compact()
            .every((v) => Array.isArray(v) && v.isEmpty) && (
            <Button label="Clear" variant="tertiary" onClick={() => setFilter({} as PurchaseOrdersFilter)} />
          )}
        </div>
      </div>

      <div css={Css.dg.gtc("1fr 1fr").gap1.my3.ifSm.df.fdc.$}>
        <div css={Css.dg.gtc("1fr 1fr 1fr").bgWhite.p3.bshBasic.jic.if(!sm).mr2.$}>
          <SummaryMetric
            title={mdAndDown ? "Active Projects" : "Active this week"}
            metric={`${activeProjectsCount} ${pluralize(activeProjectsCount, "project")}`}
          />
          <SummaryMetric
            title={mdAndDown ? "Active tasks" : "Tasks In Progress"}
            metric={`${activeTasksCount} ${pluralize(activeTasksCount, "task")}`}
          />
          <SummaryMetric
            title={mdAndDown ? "New POs" : "New POs this week"}
            metric={`${totalCommitmentCountThisWeek} ${pluralize(totalCommitmentCountThisWeek, "PO")}`}
            delta={totalCommitmentCountThisWeek - totalCommitmentCountLastWeek}
          />
        </div>

        <div css={Css.dg.bgWhite.p2.bshBasic.$}>
          <PieChart
            title="Total committed"
            total={totalCommittedInCents}
            dataset={{
              invoiced: totalBilledInCents,
              paid: totalPaidInCents,
              remaining: totalBilledInCents - totalPaidInCents,
            }}
          />
        </div>
      </div>
    </div>
  );
}
