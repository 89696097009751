import { AuthViewProps } from "@homebound/auth-components";
import { BeamProvider, Css, PreventBrowserScroll, Toast } from "@homebound/beam";
import React from "react";
import { getStage } from "src/context";
import { TradesProvider } from "src/contexts/TradesContext";
import { useCurrentUserQuery } from "src/generated/graphql-types";
import { GlobalNav } from "./GlobalNav";
import { QaEnvironmentWarning } from "./QaEnviornmentWarning";
import { FeatureFlagProvider } from "src/contexts/FeatureFlagContext";
import { ZendeskWidget } from "src/components/ZendeskWidget";

export interface PageLayoutProps extends Pick<AuthViewProps, "user" | "setIsAuthenticated"> {
  children?: React.ReactNode;
}

/** Wraps the application pages (passed as children) with our logged-in-user layout/nav. */
export function PageLayout(props: PageLayoutProps) {
  const { data } = useCurrentUserQuery();
  const stage = getStage();

  return (
    <BeamProvider>
      <TradesProvider>
        <FeatureFlagProvider>
          <ZendeskWidget />
          <PreventBrowserScroll>
            <Toast />
            <GlobalNav user={data?.currentUser!} />
            {stage === "qa" && <QaEnvironmentWarning />}
            <div css={Css.df.mh100.fdc.aifs.jcfs.$}>
              <div css={Css.w100.fg1.$}>
                <main css={Css.mvh100.df.fdc.$}>{props.children}</main>
              </div>
            </div>
          </PreventBrowserScroll>
        </FeatureFlagProvider>
      </TradesProvider>
    </BeamProvider>
  );
}
