import { PropsWithChildren, createContext, useCallback, useContext, useMemo } from "react";
import { EnabledFeatureFlagFragment, FeatureFlagType, useUserFeatureFlagsQuery } from "src/generated/graphql-types";

interface FeatureFlagContextProps {
  featureFlags: EnabledFeatureFlagFragment[];
}

export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  featureFlags: [],
});

export function FeatureFlagProvider({ children }: PropsWithChildren<unknown>) {
  const { data } = useUserFeatureFlagsQuery();
  const featureFlags = useMemo(() => data?.userFeatureFlags ?? [], [data]);
  const context: FeatureFlagContextProps = { featureFlags };

  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext);
}

export type FeatureFlagHook = {
  // Use this whenever you want to check if a feature flag is enabled (sync, no network calls)
  featureIsEnabled: (featureFlag: FeatureFlagType) => boolean;
};

export const useFeatureFlag: () => FeatureFlagHook = () => {
  const { featureFlags } = useFeatureFlagContext();

  const availableFeatures = [...featureFlags.map((ff) => ff.type.code)].unique();

  const featureIsEnabled = useCallback(
    (featureFlag: FeatureFlagType) => {
      return availableFeatures.includes(featureFlag);
    },
    [availableFeatures],
  );

  return { featureIsEnabled };
};

interface FeaturedComponentProps {
  featureFlag: FeatureFlagType;
  children?: React.ReactNode;
}

export const FeaturedComponent = ({ featureFlag, children }: FeaturedComponentProps) => {
  const { featureIsEnabled } = useFeatureFlag();
  const isFeatureEnabled = featureIsEnabled(featureFlag);
  return isFeatureEnabled ? <>{children}</> : null;
};
