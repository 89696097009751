import { AutoSaveIndicator, Css, SelectField } from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import { User_TradePartnerFragment } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks";
import { TradePartnerContactsTable } from "./components/TradePartnerContactsTable";

export function AccountSettingsPage() {
  const currentUser = useCurrentUser();
  const tradePartners = useMemo(
    () => currentUser.tradePartnerUsers?.map((tpu) => tpu.tradePartner).uniqueByKey("id"),
    [currentUser],
  );
  const [selectedTradePartner, setSelectedTradePartner] = useState<User_TradePartnerFragment | undefined>();

  // Set the first trade partner as the default option
  useEffect(() => {
    if (currentUser) {
      setSelectedTradePartner(currentUser.tradePartnerUsers?.first?.tradePartner);
    }
  }, [currentUser]);

  return (
    <div css={Css.bgGray100.h100.w100.fg1.bgGray100.$}>
      <div css={Css.df.fdr.bgWhite.p4.pt3.jcfs.ifSm.fdc.$}>
        <div>
          {selectedTradePartner && <div css={Css.xs.gray700.mbPx(4).$}>{selectedTradePartner.name}</div>}
          <div css={Css.xl2Sb.df.gap3.ifSm.lgSb.mya.$}>
            Account Settings <AutoSaveIndicator />
          </div>
        </div>
        {/* As a designer request, we'll only show the select TP field to users with multiple trades */}
        {/* TODO: remove this field once the trade partner selection in the user profile is implemented */}
        {tradePartners && tradePartners.length > 1 && (
          <div css={Css.mla.mt1.ifSm.mt2.$}>
            <SelectField
              options={tradePartners}
              onSelect={(_, tp) => setSelectedTradePartner(tp)}
              value={selectedTradePartner?.id}
              label="Select Trade Partner"
              labelStyle="inline"
            />
          </div>
        )}
      </div>
      <div css={Css.px4.pt4.$}>
        {selectedTradePartner && <TradePartnerContactsTable tradePartnerId={selectedTradePartner.id} />}
      </div>
    </div>
  );
}
