import { Css, Icon, IconProps, Palette, useTestIds } from "@homebound/beam";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { StateHook } from "src/utils";

interface MobileLink {
  value?: string;
  label: string;
  path: string;
}

export interface MobileNavProps {
  links: MobileLink[];
  openState: StateHook<boolean>;
  onSignOut: () => void;
}

export function MobileNav({ links, openState: [open, setOpen], onSignOut }: MobileNavProps) {
  const history = useHistory();
  const tid = useTestIds({}, "mobileNav");

  // Always close the menu when navigating
  useEffect(() => {
    setOpen(false);
  }, [history.location, setOpen]);

  return (
    <div>
      <span css={Css.cursorPointer.$} onClick={() => setOpen(!open)} {...tid.menu}>
        <Icon icon="menu" color={Palette.White} inc={4} />
      </span>
      {open && (
        <div css={Css.position("absolute").left0.bgGray800.w100.$}>
          <div css={Css.my2.$}>
            <ul css={Css.listReset.db.$}>
              {links.map((item) => (
                <ItemGroup {...item} target={item.path} key={item.value} />
              ))}
              <ItemSeparator />
              <ItemGroup icon="helpCircle" label="Support Guide" target="https://tradesupport.homebound.com/hc" />
              <ItemGroup icon="email" label="Contact Us" target="mailto:product-support@homebound.com" />
              <ItemGroup label="Sign Out" onClick={onSignOut} />
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

interface ItemGroupProps {
  label: string;
  target?: string;
  icon?: IconProps["icon"];
  onClick?: () => void;
}

function ItemGroup(props: ItemGroupProps) {
  const { target, label } = props;
  const itemTargetLabel = (
    <div css={Css.df.fdr.aic.$}>
      <ItemText {...props} />
    </div>
  );
  return (
    <li css={Css.df.pl3.fdr.aic.cursorPointer.lh("20px").mh("42px").onHover.bgGray900.$} key={label}>
      {target ? (
        target.startsWith("mailto") || target.startsWith("https") ? (
          // Use anchor tag for external links
          <a href={target} data-testid="mobileNav_link">
            {itemTargetLabel}
          </a>
        ) : (
          <Link data-testid="mobileNav_link" to={target}>
            {itemTargetLabel}
          </Link>
        )
      ) : (
        <ItemText {...props} />
      )}
    </li>
  );
}

function ItemText({ label, icon, onClick }: Pick<ItemGroupProps, "label" | "onClick" | "icon">) {
  return (
    <>
      {icon && <Icon icon={icon} inc={3} color={Palette.White} />}
      <span onClick={onClick} css={Css.white.ml1.lg.$}>
        {label}
      </span>
    </>
  );
}

function ItemSeparator() {
  return (
    <div css={Css.df.my2.jcc.$}>
      <li css={Css.bc("gray").bss.bt.w("90%").$} />
    </div>
  );
}
