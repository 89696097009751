import { useSessionStorage } from "@homebound/beam";
import { useEffect } from "react";
import { safeKeys } from "src/utils";
import { JsonParam, useQueryParams } from "use-query-params";

interface UseQueryStorageProps<F> {
  initialQueryStorage: F;
  storageKey: string;
}
type QueryStorageHook<F> = {
  queryStorage: F;
  setQueryStorage: (filter: F) => void;
};

export function useQueryStorage<F extends object>({
  initialQueryStorage,
  storageKey,
}: UseQueryStorageProps<F>): QueryStorageHook<F> {
  const keys = Object.keys(initialQueryStorage);

  const [params, setQueryParams] = useQueryParams({ [storageKey]: JsonParam });
  // key in session storage and query param
  const queryParams = params[storageKey];
  const [storedFilter, setStoredFilter] = useSessionStorage<F>(storageKey, queryParams);
  const isQueryParamFilterValid = hasValidFilterKeys(queryParams, keys);
  const queryStorage: F = parseDateParams(isQueryParamFilterValid ? queryParams : storedFilter ?? initialQueryStorage);
  // set storage
  const setQueryStorage = (filter: F) => setQueryParams({ [storageKey]: filter });
  useEffect(
    () => {
      if (queryParams === undefined) {
        // if there is no filter in the query params, use stored filter
        // "replaceIn" replaces the url in history instead of creating a new history item
        // back button will go to previous url
        setQueryParams({ [storageKey]: storedFilter }, "replaceIn");
      } else if (!isQueryParamFilterValid) {
        // if there are invalid query params, fallback to the default filters
        setQueryParams({ [storageKey]: storedFilter }, "replaceIn");
      } else if (JSON.stringify(queryParams) !== JSON.stringify(storedFilter)) {
        // if there is a valid filter in query params and its different from the
        // current storedFilter, use query params filter
        setStoredFilter(queryParams);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-react-projects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedFilter, setStoredFilter, setQueryParams, queryParams],
  );

  return { setQueryStorage, queryStorage };
}

// check for valid filter keys in the query params
function hasValidFilterKeys<F extends object>(queryParamsFilter: F, definedKeys: (keyof F)[]): queryParamsFilter is F {
  return queryParamsFilter && safeKeys(queryParamsFilter).every((key) => definedKeys.includes(key));
}

function parseDateParams<F extends object>(params: F): F {
  const dateISOFormatRegex =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

  for (const key in params) {
    const vals = {
      from: (params[key] as any)?.value?.from,
      to: (params[key] as any)?.value?.to,
    };
    // if the param has the date range object format
    Object.entries(vals).forEach(([prop, value]) => {
      if (typeof value === "string" && dateISOFormatRegex.test(value)) {
        (params[key] as any).value[prop] = new Date(value);
      }
    });
  }
  return params;
}
