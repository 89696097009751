import { Maybe, Order } from "src/generated/graphql-types";
import { fail } from "src/utils";

/** Converts a GQL `{ [field]: Order.Asc/Desc }` record into a `[field, direction]` tuple. */
export function parseOrder<T extends Record<string, Maybe<Order | string>>>(order: T): [keyof T, Order] | undefined {
  const key = Object.keys(order)[0] || fail();
  const value = order[key];
  return value !== undefined && value !== null && (value === Order.Asc || value === Order.Desc)
    ? [key, value]
    : undefined;
}

export function toOrder<K extends string>(key: K | undefined, direction: "ASC" | "DESC" | undefined): Record<K, Order> {
  return (key && direction ? { [key]: direction === "ASC" ? Order.Asc : Order.Desc } : {}) as Record<K, Order>;
}
