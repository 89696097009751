import {
  actionColumn,
  Button,
  Chips,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  HasIdAndName,
  IconButton,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
  UseModalHook,
} from "@homebound/beam";
import { useMemo } from "react";
import { QueryResultHandler } from "src/components";
import {
  ContactsTabTradePartnerContactFragment,
  TradePartnerContactsQuery,
  useTradePartnerContactsQuery,
} from "src/generated/graphql-types";
import { TradePartnerContactModal } from "./TradePartnerContactModal";
import { TradePartnerContactsRolesPanel } from "./TradePartnerContactsRolesPanel";

export interface TradePartnerContactsTableProps {
  tradePartnerId: string;
}

export function TradePartnerContactsTable({ tradePartnerId }: TradePartnerContactsTableProps) {
  const query = useTradePartnerContactsQuery({ variables: { tradePartnerId } });

  return QueryResultHandler<TradePartnerContactsQuery>({
    result: query,
    render: TradePartnerContactsTableView,
  });
}

function TradePartnerContactsTableView(props: TradePartnerContactsQuery) {
  const { tradePartner, markets } = props;
  const modal = useModal();
  const columns = useMemo(() => createColumns(tradePartner, modal, markets), [modal, tradePartner, markets]);
  const rows: GridDataRow<Row>[] = useMemo(() => simpleDataRows(tradePartner.contacts), [tradePartner.contacts]);

  return (
    <div css={Css.df.ifMdAndDown.fdc.$}>
      <div css={Css.mw0.w100.$}>
        <div css={Css.df.mb1.jcsb.aic.$}>
          <div css={Css.lgBd.pb2.$}>Contacts</div>
          <Button
            label="Add Contact"
            icon="plus"
            variant="tertiary"
            onClick={() =>
              modal.openModal({
                content: (
                  <TradePartnerContactModal tradePartnerId={tradePartner.id!} contact={undefined} markets={markets} />
                ),
              })
            }
          />
        </div>
        <div css={Css.boxShadow("0 0 24px 0 rgba(53, 53, 53, 0.08)").$}>
          <GridTable
            stickyHeader
            style={{ allWhite: true }}
            columns={columns}
            rows={rows}
            fallbackMessage="No contacts found."
            sorting={{ on: "client" }}
          />
        </div>
      </div>
      <div css={Css.ml3.ifMdAndDown.ml0.mt3.$}>
        <TradePartnerContactsRolesPanel
          tradePartnerId={tradePartner.id}
          roles={tradePartner.marketContacts}
          contacts={tradePartner.contacts}
          markets={tradePartner.markets}
        />
      </div>
    </div>
  );
}

type Column = GridColumn<Row>;
type Row = SimpleHeaderAndData<ContactsTabTradePartnerContactFragment>;

function createColumns(tradePartner: { id: string }, modal: UseModalHook, markets: HasIdAndName[]): Column[] {
  return [
    column<Row>({
      header: "Name",
      data: (tpc) => ({
        content: (
          <div>
            <div css={Css.xsMd.gray900.$}>{tpc.name}</div>
            {tpc.title && <div css={Css.xs.gray700.$}>{tpc.title}</div>}
          </div>
        ),
        value: `${tpc.name} ${tpc.title}`,
      }),
    }),
    column<Row>({
      header: "Markets",
      data: (tpc) => <Chips values={tpc.markets.map((m) => m.name)} xss={Css.mt1.mb0.$} />,
      clientSideSort: false,
    }),
    column<Row>({
      header: "Phone",
      data: (tpc) => {
        return (
          <div css={Css.gray700.$}>
            {tpc.officePhone && <div>O: {tpc.officePhone}</div>}
            {tpc.mobilePhone && <div>C: {tpc.mobilePhone}</div>}
          </div>
        );
      },
      clientSideSort: false,
    }),
    column<Row>({
      header: "Email",
      data: (tpc) => ({
        content: (
          <div css={Css.xsMd.wbba.$}>
            <a href={"mailto:" + tpc.email}>{tpc.email}</a>
          </div>
        ),
        value: tpc.email,
      }),
      w: 1.2,
    }),
    actionColumn<Row>({
      header: () => undefined,
      data: (tpc) => {
        return (
          <IconButton
            label="Edit"
            icon="pencil"
            onClick={() =>
              modal.openModal({
                content: <TradePartnerContactModal tradePartnerId={tradePartner.id} contact={tpc} markets={markets} />,
              })
            }
          />
        );
      },
      w: 0.5,
    }),
  ];
}
