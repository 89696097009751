import {
  PurchaseOrderPage_ChangeOrderFragment,
  PurchaseOrderPage_CommitmentFragment,
} from "src/generated/graphql-types";

export enum OptionTypes {
  Elevation = "Elevation",
  ExteriorScheme = "Exterior Scheme",
  AddOn = "Add-on",
  FloorPlan = "Floor Plan",
  Interior = "Interior",
  Custom = "Custom",
}

export type CommitmentLikeType = PurchaseOrderPage_ChangeOrderFragment | PurchaseOrderPage_CommitmentFragment;
