import { Chip, GridColumn, GridDataRow, GridTable, column, emptyCell, simpleHeader } from "@homebound/beam";
import { Link, useParams } from "react-router-dom";
import { FormattedDate, Price } from "src/components";
import { QueryResultHandler } from "src/components/QueryResultHandler";
import {
  PurchaseOrderDetailPane_BillFragment,
  PurchaseOrderDetailPane_InvoicesTabQuery,
  usePurchaseOrderDetailPane_InvoicesTabQuery,
} from "src/generated/graphql-types";
import { getInvoiceUrl, purchaseOrderParam } from "src/routes/routesDef";
import { billStatusChipTypeMapper } from "src/utils";

export function PurchaseOrderInvoicesTab() {
  const { purchaseOrderId, changeOrderId } = useParams<purchaseOrderParam>();
  const query = usePurchaseOrderDetailPane_InvoicesTabQuery({
    variables: {
      commitmentId: purchaseOrderId ?? "",
      changeOrderId: changeOrderId ?? "",
      // Use a skip directive inline to prevent querying a commitment/cco depending on which page we've requested
      // Allows us to also handle the request for one or the other in the same query
      skipCommitment: !!changeOrderId,
      skipChangeOrder: !changeOrderId,
    },
  });

  return QueryResultHandler<PurchaseOrderDetailPane_InvoicesTabQuery>({
    result: query,
    render: PurchaseOrderInvoiceTabView,
  });
}

function PurchaseOrderInvoiceTabView(props: PurchaseOrderDetailPane_InvoicesTabQuery) {
  const { projectId } = useParams<purchaseOrderParam>();
  const { commitment, commitmentChangeOrder } = props;

  const commitmentLike = commitment ?? commitmentChangeOrder;
  const { bills } = commitmentLike ?? {};

  return <GridTable rows={rows(bills)} columns={columns(projectId)} style={{ allWhite: true, bordered: false }} />;
}

type HeaderRow = { kind: "header" };
type BillRow = { kind: "bill"; data: PurchaseOrderDetailPane_BillFragment };
type Row = HeaderRow | BillRow;

function rows(bills: PurchaseOrderDetailPane_BillFragment[] | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(bills || []).map((b) => ({
      kind: "bill" as const,
      id: b.id,
      data: b,
    })),
  ];
}

function columns(projectId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      id: "accountingNumbr",
      header: "Invoice #",
      bill: (b) => (
        <Link to={getInvoiceUrl(projectId, b.id)} target="_blank">
          #{b.tradePartnerNumber}
        </Link>
      ),
      w: "110px",
    }),
    column<Row>({
      id: "status",
      header: emptyCell,
      bill: (b) => {
        const [type, text] = billStatusChipTypeMapper(b.status.code);
        return <Chip type={type} text={text} compact />;
      },
      w: "100px",
    }),
    column<Row>({
      id: "paidOn",
      header: "Paid on",
      bill: (b) => <FormattedDate date={b.paidDate ?? undefined} />,
      w: "80px",
    }),
    column<Row>({
      id: "total",
      header: "Invoice total",
      bill: (b) => <Price valueInCents={b.billedInCents} />,
      w: "100px",
    }),
  ];
}
