import { AuthRouteProps, AuthenticatedRoute, GoogleCallback, UnauthenticatedRoute } from "@homebound/auth-components";
import { Redirect, Switch } from "react-router-dom";
import { AccountSettingsPage } from "./routes/accountSettings/AccountSettingsPage";
import { SignIn } from "./routes/auth/SignIn";
import { InvoicePage } from "./routes/invoices/InvoicePage";
import { InvoicesPage } from "./routes/invoices/InvoicesPage";
import { PurchaseOrderPage } from "./routes/purchaseOrders/PurchaseOrderPage";
import { PurchaseOrdersPage } from "./routes/purchaseOrders/PurchaseOrdersPage";
import { accountsSettingsPath, invoicesPath, projectPaths, purchaseOrdersPath, schedulePath } from "./routes/routesDef";
import { SchedulesPage } from "./routes/schedules/SchedulesPage";

export type SignInRouteProps = { linkCode?: string };

export type MockLayoutType = {
  // For testing purposes
  mockLayout?: (component: React.ReactNode) => JSX.Element;
};

export function Routes({ authProps, mockLayout }: AuthRouteProps & MockLayoutType) {
  return (
    <Switch>
      <UnauthenticatedRoute
        path={["/login", "/enterCode", "/verifyCode/:linkCode(\\d+)"]}
        exact
        component={SignIn}
        authProps={authProps}
      />

      <UnauthenticatedRoute path="/auth/gcallback" exact component={GoogleCallback} authProps={authProps} />

      <Redirect exact from="/" to={purchaseOrdersPath} />
      <AuthenticatedRoute path={purchaseOrdersPath} exact component={PurchaseOrdersPage} authProps={authProps} />
      <AuthenticatedRoute path={projectPaths.purchaseOrder} exact component={PurchaseOrderPage} authProps={authProps} />
      <AuthenticatedRoute path={projectPaths.changeOrder} exact component={PurchaseOrderPage} authProps={authProps} />
      <AuthenticatedRoute path={invoicesPath} exact component={InvoicesPage} authProps={authProps} />
      <AuthenticatedRoute path={projectPaths.invoice} exact component={InvoicePage} authProps={authProps} />
      <AuthenticatedRoute path={schedulePath} exact component={SchedulesPage} authProps={authProps} />
      <AuthenticatedRoute path={accountsSettingsPath} exact component={AccountSettingsPage} authProps={authProps} />
    </Switch>
  );
}
