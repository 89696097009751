import { z } from "zod";
import { Rule } from "@homebound/form-state";
import { differenceInBusinessDays } from "date-fns";
import { Maybe } from "graphql/jsutils/Maybe";
import { newComparator } from "src/utils/arrays";

export function fail(message?: string): never {
  throw new Error(message || "Failed");
}

export const sum = (a: number, b: number) => a + b;

// Even though we use Math.max, this declaration is more reduce-friendly.
export const max = (a: number, b: number) => Math.max(a, b);

/** Returns a `T` that actually has no keys defined; very unsafe but nice to have for default form inputs. */
export function empty<T>(): T {
  return {} as any as T;
}

// A nice type alias for hooks instead of the [T, Dispatch<...>] nonsense.
export type StateHook<T> = [T, (value: T) => void];

export function groupBy<T, Y = T>(
  list: T[] | ReadonlyArray<T>,
  fn: (x: T) => string,
  valueFn?: (x: T) => Y,
  sortFn?: (x: Y) => number | string,
): Record<string, Y[]> {
  const result: Record<string, Y[]> = {};
  list.forEach((o) => {
    const group = fn(o);
    if (result[group] === undefined) {
      result[group] = [];
    }
    result[group].push(valueFn === undefined ? (o as any as Y) : valueFn(o));
  });
  if (sortFn) {
    Object.keys(result).forEach((key) => {
      result[key].sort(newComparator(sortFn));
    });
  }
  return result;
}

type Builtin = Date | Function | Uint8Array | string | number | undefined | boolean;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends Record<string, any>
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export function zeroTo(n: number): number[] {
  return [...Array(n).keys()];
}

// Examples: pluralize(tacoCount, "taco"); pluralize(boxCount, "box", "boxes");
// Automatic "s" is for convenience in simple cases; when in doubt, supply the full plural noun
export function pluralize(count: number | unknown[], noun: string, pluralNoun?: string): string {
  if ((Array.isArray(count) ? count.length : count) === 1) return noun;
  return pluralNoun || `${noun}s`;
}

export function count(count: number, noun: string, pluralNoun?: string): string;
export function count(array: unknown[], noun: string, pluralNoun?: string): string;
export function count(countOrArray: unknown[] | number, noun: string, pluralNoun?: string): string {
  const count = Array.isArray(countOrArray) ? countOrArray.length : countOrArray;
  return `${count} ${pluralize(count, noun, pluralNoun)}`;
}

/** Casts `Object.keys` to "what it should be", as long as your instance doesn't have keys it shouldn't. */
export function safeKeys<T>(instance: T): (keyof T)[] {
  return Object.getOwnPropertyNames(instance) as any;
}

/** Casts `Object.entries` to "what it should be", as long as your record doesn't have keys it shouldn't. */
export function safeEntries<K extends keyof any, V>(record: Record<K, V>): [K, V][] {
  return Object.entries(record) as [K, V][];
}

/** used to debug re-rendering issues */
export const objectId = (() => {
  let currentId = 0;
  const map = new WeakMap();
  return (object: object): number => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }
    return map.get(object)!;
  };
})();

/** Returns the number suffix of a tagged Id ("p:1" -> 1) */
export function removeTag(id: string) {
  return Number(id.split(":").pop());
}

/** No operation function, can be used to default react props */
export function noop() {}

export function partition<T>(array: ReadonlyArray<T>, f: (el: T) => boolean): [T[], T[]] {
  const trueElements: T[] = [];
  const falseElements: T[] = [];

  array.forEach((el) => {
    if (f(el)) {
      trueElements.push(el);
    } else {
      falseElements.push(el);
    }
  });

  return [trueElements, falseElements];
}

export function unique<T>(array: ReadonlyArray<T>) {
  return [...new Set(array)];
}

export function nonEmpty<T>(array: ReadonlyArray<T>): boolean {
  return array.length > 0;
}

export function isEmpty<T>(array: ReadonlyArray<T>): boolean {
  return array.length === 0;
}
/**
 * Dedupe an array of objects by a given object key
 * @param array An array of objects
 * @param key Object key used to find duplicates
 */
export function uniqueByKey<T extends object>(array: ReadonlyArray<T>, key: keyof T) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

/** Smartly de tag ids only when its in a id form i:12 */
export function maybeDeTagId(id: string) {
  const untaggedId = id.match(/[^\d\W]+:(?<id>\d+)/)?.groups?.id;
  return untaggedId ?? id;
}

/**
 * Effectively a switch-statement on Enums. Identifies an unknown Enum value and
 * maps it down to a given value. Also works on string-union types.
 */
export function foldEnum<T extends string | number, F extends (() => unknown) | unknown>(
  value: T,
  map: { [key in T]: F } | ({ [key in T]?: F } & { else: F }),
): F extends () => unknown ? ReturnType<F> : F {
  const item = map[value] ?? (map as any).else;
  return typeof item === "function" ? item() : item;
}

export function capitalizeFirstWord(s: string): string {
  const lc = s.toLowerCase();
  const sArray = lc.split("");
  sArray[0] = sArray[0].toUpperCase();
  return sArray.join("");
}

export function capitalize(s: string): string {
  const lc = s.toLowerCase();
  const sArray = lc.split(" ");
  const capitlized = sArray.map((str) => str.charAt(0).toUpperCase() + str.slice(1));
  return capitlized.join(" ");
}

export function mostFrequentNumber(arr: number[]): number {
  const initValue: Record<number, number> = {};
  const hashmap = arr.reduce((elements, value) => {
    elements[value] = (elements[value] || 0) + 1;
    return elements;
  }, initValue);
  return parseFloat(Object.keys(hashmap).reduce((a, b) => (hashmap[parseFloat(a)] > hashmap[parseFloat(b)] ? a : b)));
}

/**  Format an array [A, B, C] into a string: "A, B and C" */
export function formatList(elements: string[] | number[]): string {
  const result = elements.map((e, i, arr) => {
    return i < arr.length - 2 ? `${e}, ` : i < arr.length - 1 ? `${e} and ` : e;
  });
  return result.join("");
}

export function dollarStringToCents(dollars: string | number): number {
  if (typeof dollars === "number") {
    dollars = String(dollars);
  }

  if (dollars === "") {
    throw new Error("input is empty or blank");
  }

  const isNegative = dollars.startsWith("-");

  const NotNumOrDecimal = new RegExp(/[^\d\.]/gi);
  const sanitizedString = dollars.replaceAll(NotNumOrDecimal, ""); // "$1,234 567.89" --> "1234567.89"

  /** Rejects `1.234` for having too many decimal places */
  const MoreThanTwoDecimalPlaces = new RegExp(/\.\d{3,}$/);
  if (MoreThanTwoDecimalPlaces.test(sanitizedString)) {
    throw new Error(`Bad number provided: \`${dollars}\`. Too many digits after the decimal.`);
  }

  /** Rejects `1.234.567.89` for having too many periods. We're not expecting EU formatting */
  const decimalCount = sanitizedString.split("").reduce((count, char) => (char === "." ? count + 1 : count), 0);
  if (decimalCount > 1) {
    throw new Error(`Too many decimal points provided for number \`${dollars}\``);
  }

  /** Rejects `1,` and `1,2` and `1,23` but `1,234` is valid */
  const EndedWithCommas = new RegExp(/,\d{0,2}$/);
  if (EndedWithCommas.test(dollars)) {
    throw new Error(`Number \`${dollars}\` is invalid. Typo or possible EU Formatting rejected?`);
  }

  return (
    Math.round(
      Number.parseFloat(
        // Expand 100 -> "100.00", or 123.4 --> "123.40", etc
        Number.parseFloat(sanitizedString).toFixed(2),
      ) * 100, // To Cents
    ) * (isNegative ? -1 : 1) // handle Negative
  );
}

export const USStates =
  `AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI WY`
    .trim()
    .split(" ")
    .map((name) => ({ name }));

export const tableHeightWithPagination = "62vh";

export function qaServerEnvironment() {
  if ("VITE_GRAPHQL_SERVICE" in global && VITE_GIT_BRANCH?.startsWith("qa-")) {
    const qaCustomBeBranch =
      VITE_GIT_BRANCH.split("@").first ??
      fail("Invalid BE Feature Branch - please ensure branch name matches pattern 'qa-[story|epic]@[sub-story-branch]");
    return { subDomain: `${qaCustomBeBranch}.graphql`, qaCustomBeBranch };
  }
  return { subDomain: "graphql", qaCustomBeBranch: undefined };
}

/**
 * Util to print `3 days ago` or `in 1 day` by passing in a target date and
 * comparing to right now.
 */
export function daysAgo(targetDate: Date): string {
  const rtfl = new Intl.RelativeTimeFormat();
  const diff = differenceInBusinessDays(targetDate, new Date());
  if (diff === 0) return "today";
  return rtfl.format(diff, "day");
}

interface InfiniteScrollOptions {
  // In case the dataKey is not `entities` you can specify it here
  dataKey: string;
}

export function infiniteScroll({ dataKey = "entities" }: InfiniteScrollOptions | undefined = { dataKey: "entities" }) {
  return {
    keyArgs: (args: Record<string, any> | null) => {
      // Return all args keys except `page`
      const { page, ...rest } = args ?? {};
      return Object.keys(rest).sort();
    },
    merge(existing: any, incoming: any) {
      const merged = existing ?? {
        [dataKey]: [],
        pageInfo: { hasNextPage: true },
      };
      // Merge the new entities into the existing entities
      // And deduplicate the results
      const mergedEntities = [...merged[dataKey], ...incoming[dataKey]];
      const uniqueEntities = mergedEntities.reduce((acc: any, entity: any) => {
        if (!acc.some((e: any) => e.__ref === entity.__ref)) {
          acc.push(entity);
        } else {
          console.log("Skipping duplicate entity");
        }
        return acc;
      }, []);
      return {
        ...incoming,
        [dataKey]: uniqueEntities,
      };
    },
  };
}

export const emailFormatRule: Rule<Maybe<string>> = ({ value }) => {
  // If trade partner email is undefined or null consider it valid format
  if (value === undefined || value === null) {
    // undefined triggers required field error
    return undefined;
  } else if (
    // If trade partner email format does not pass validation consider it invalid
    // validation check is a simple `string@string.string` rule
    !value.toLowerCase().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  ) {
    return "Invalid format";
  }
};

/**
 * This is a schema parser for the `page` query param.
 */
export const pageSchema = z.object({
  offset: z.coerce.number().int().default(0),
  limit: z.coerce.number().int().positive().default(100),
});

/**
 * This is a schema parser for the `search` query param.
 */
export const searchSchema = z.object({
  search: z.coerce.string().default(""),
});
