import { ApolloClient } from "@apollo/client";
import "@homebound/activesupport";
import { AmplifyConfig, HomeboundUser, createApolloClientWithAuth } from "@homebound/auth-components";
import md5 from "md5";
import { possibleTypes } from "src/generated/graphql-types";
import { typePolicies } from "src/typePolicies";
import { qaServerEnvironment } from "./utils";

type Stage = "local" | "dev" | "qa" | "prod";
export type WindowWithHeap = Window & typeof globalThis & { heap: Heap };

export function getStage(windowLocation = window.location): Stage {
  // Guess stage based on the current window.location.hostname
  const hostname = windowLocation.hostname;
  if (hostname.includes("dev-homebound")) {
    return "dev";
  } else if (hostname.includes("qa-homebound")) {
    return "qa";
  } else if (hostname.includes("homebound")) {
    return "prod";
  } else {
    return "local";
  }
}

export function isContentPreview(windowLocation = window.location): boolean {
  return /.*\.trade\.(dev|qa)-homebound\.com/.test(windowLocation.hostname);
}

// TODO: update prod to prod gql when BE merged to prod
export function getGraphQLBaseUrl(stage: Stage = getStage()): string {
  const stageToGraphQL: Record<Stage, string> = {
    local: "VITE_GRAPHQL_SERVICE" in global && VITE_GRAPHQL_SERVICE ? VITE_GRAPHQL_SERVICE : "http://localhost:4000",
    dev: "https://graphql.dev-homebound.com",
    qa: `https://${qaServerEnvironment().subDomain}.qa-homebound.com`,
    prod: "https://graphql.homebound.com",
  };
  return stageToGraphQL[stage];
}

export function createApolloClient(stage: Stage): ApolloClient<unknown> {
  const baseUrl = getGraphQLBaseUrl(stage);
  return createApolloClientWithAuth(`${baseUrl}/graphql`, {
    retryLinkOptions: {
      attempts: {
        retryIf(error: any) {
          if (error instanceof TypeError) {
            console.error("Retrying because fetched rejected: " + error.message);
          }
          // For a failed mutation, error will be a ServerError (error.name === 'ServerName'), don't retry
          // For a rejected fetch (likely transient connectivity issue), error will be a TypeError, so retry
          return error instanceof TypeError;
        },
      },
    },
    apolloClientOptions: {
      connectToDevTools: stage !== "prod",
      assumeImmutableResults: true,
      defaultOptions: {
        // Prefer consistency on CRUD tables/forms. Use watchQuery b/c that's what React's hooks use.
        // For nextFetchPolicy, see https://github.com/apollographql/apollo-client/pull/6712
        watchQuery: { fetchPolicy: "network-only", nextFetchPolicy: "cache-first" },
        query: { fetchPolicy: "network-only" },
      },
    },
    inMemoryCacheOptions: {
      // `as any` because our possibleTypes is generated with `as const` which makes readonly arrays,
      // but Apollo accepts mutable arrays.
      possibleTypes: possibleTypes as any,
      typePolicies,
    },
  });
}

/** Setup amplify config based on stage **/
export function amplifyConfig(stage: Stage = getStage()): AmplifyConfig {
  function getStageConfig() {
    if (stage === "prod") {
      return {
        userPoolId: "us-west-2_sBiSmiFZz",
        userPoolWebClientId: "60oueakstobnoeb0uc73jrt7j3",
        oauthDomain: "trades-auth.homebound.com",
      };
    }

    return {
      userPoolId: "us-west-2_wwRF4kAAm",
      userPoolWebClientId: "6o6nj5t7cp0iaq0g7ukhrtmq28",
      oauthDomain: "trades-auth.dev-homebound.com",
    };
  }

  const { userPoolId, userPoolWebClientId, oauthDomain } = getStageConfig();
  const { origin } = window.location;

  return {
    Auth: {
      region: "us-west-2",
      userPoolId,
      userPoolWebClientId,
      authenticationFlowType: "CUSTOM_AUTH",
      oauth: {
        domain: oauthDomain,
        scope: ["profile", "email", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: origin + "/auth/gcallback",
        redirectSignOut: origin + "/",
        responseType: "code",
      },
    },
  };
}

/** Create userId hash from user email */
function md5UserId(user: HomeboundUser | undefined) {
  if (user) {
    return md5(user.email);
  }
}

interface Heap {
  identify: (email?: string) => void;
}

/** Identify user based on the hashed id*/
export function identifyHeap(user: HomeboundUser | undefined) {
  if (user) {
    (window as WindowWithHeap).heap?.identify(md5UserId(user));
  }
}
