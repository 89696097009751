import { Css, useTestIds } from "@homebound/beam";
import { emptyCellDash } from "src/components/gridTableCells";
import { Maybe } from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString, roundCentsToDollars } from "src/utils";

export interface PriceProps {
  id?: string;
  valueInCents: Maybe<number>;
  dropZero?: boolean;
  /** Prefixes a plus for positive numbers, like +$1,000. Negatives will always display */
  displayDirection?: boolean;
  /** Makes positive numbers red (e.g. price increase) and negative colors green */
  invertColors?: boolean;
  /** trims trailing zeros after decimal point */
  trim?: boolean;
  /** Pairs with displayDirection + invertColors to determine how to display Zero. Neutral overrides either to display without color. */
  zeroIs?: "positive" | "negative" | "neutral";
  rounded?: boolean;
}

export function Price(props: PriceProps) {
  const {
    id,
    valueInCents,
    dropZero = false,
    displayDirection = false,
    trim = false,
    invertColors = false,
    zeroIs = "positive", // This is less a default than an expected byproduct of !!valueInCents
    rounded = false,
  } = props;
  const rootId = useTestIds({}, id || "price");
  let isNegative = !!valueInCents && valueInCents < 0;
  const prefix = isNegative ? "-$" : displayDirection ? "+$" : "$";
  const isZero = valueInCents === 0;
  if (isZero && zeroIs === "negative") isNegative = !isNegative;
  const styles = isZero && zeroIs === "neutral" ? Css.$ : redOrGreen(displayDirection, isNegative, invertColors);
  const value = rounded
    ? roundCentsToDollars(Math.abs(valueInCents || 0))
    : centsToDollars(Math.abs(Number(valueInCents)));
  const formattedPrice =
    typeof valueInCents !== "number" || (dropZero && valueInCents === 0)
      ? emptyCellDash
      : `${prefix}${formatNumberToString(value, trim)}`;
  return (
    <span {...rootId} css={styles}>
      {formattedPrice}
    </span>
  );
}

function redOrGreen(displayDirection: boolean, isNegative: boolean, invertColors: boolean) {
  let green = displayDirection && !isNegative ? true : isNegative ? false : undefined;
  if (green === undefined) return;
  if (invertColors) green = !green;
  return green ? Css.green800.$ : Css.red600.$;
}
