import {
  Avatar,
  Button,
  Css,
  Icon,
  IconButton,
  Palette,
  RichTextField,
  useComputed,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormattedDate, QueryResultHandler } from "src/components";
import { UppyUploader } from "src/components/UppyUploader";
import {
  ApproverStatus,
  AssetDetailsFragment,
  AssetInput,
  CommentStreamVisibility,
  InvoicePageDocument,
  InvoiceRequestChangesDocument,
  InvoiceRequestChangesQuery,
  ProjectRole,
  SaveAttachmentInput,
  useInvoiceRequestChangesPane_SaveApproverMutation,
  useInvoiceRequestChangesQuery,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks";
import { PageHeader } from "src/routes/layout/PageHeader";
import { invoiceParam } from "src/routes/routesDef";
import { sanitizeHtml } from "src/utils/sanitizeHtml";
import { disableApprovalReason } from "../InvoicePage";

export function InvoiceRequestChangesPane() {
  const { billId } = useParams<invoiceParam>();
  const query = useInvoiceRequestChangesQuery({
    variables: { billId: billId ?? "" },
    skip: !billId,
  });

  return QueryResultHandler<InvoiceRequestChangesQuery>({
    result: query,
    render: InvoiceRequestChangesPaneView,
  });
}

export function InvoiceRequestChangesPaneView(props: InvoiceRequestChangesQuery) {
  const { bill } = props;

  const currentUser = useCurrentUser();
  const { triggerNotice } = useSnackbar();
  const { closeRightPane } = useRightPane();
  const [saveApprover] = useInvoiceRequestChangesPane_SaveApproverMutation();
  const tid = useTestIds({});

  const existingAttachments = useMemo(
    () => bill.approval?.streams.flatMap((s) => s.comments).flatMap((c) => c.attachments) || [],
    [bill.approval?.streams],
  );

  const existingComments = useMemo(
    () => bill.approval?.streams.flatMap((s) => s.comments.first?.html) || [],
    [bill.approval?.streams],
  );

  // Get the current approver for this bill
  const billApprovers = bill.approval?.approvers;

  const tpus = currentUser.tradePartnerUsers.map((tpu) => tpu.id);
  const currentUserApprover = billApprovers?.find((approver) => tpus.includes(approver.user.id));

  const disabledReason = disableApprovalReason(bill.status.code, currentUserApprover?.id);

  const canRequestChanges = disabledReason === undefined;

  const formState = useFormState({
    config: formConfig,
    init: {
      text: "",
      // C2P TODO: The change request reason wont always
      // be the first comment in the stream.  For now we don't allow trade user to
      // reopen the requestChange panel. Future solve maybe to add a trade comment stream here.
      html: existingComments.first ?? "",
      attachments: existingAttachments,
    },
    readOnly: !canRequestChanges,
  });
  const orderedAttachments = useComputed(
    () => formState.attachments.rows.filter((a) => !a.asset.delete.value).sortBy((x) => -x.asset.fileName),
    [formState],
  );
  const changeReason = useComputed(() => formState.html.value, [formState.html]);

  const onEditorChange = useCallback(
    (newHtml: string = "", newText: string = "") => {
      formState.html.set(newHtml);
      formState.text.set(newText);
    },
    [formState.html, formState.text],
  );

  const saveAttachmentToForm = useCallback(
    (file: UppyFile) => {
      const downloadUrl = file.meta.downloadUrl as string;
      formState.attachments.add({
        asset: {
          downloadUrl,
          contentType: file.type!,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
          attachmentUrl: downloadUrl,
          createdAt: new Date(),
          delete: false,
        },
      });
    },
    [formState.attachments],
  );

  const handleRequestChanges = useCallback(async () => {
    const sanitized = sanitizeHtml(formState.html.value);

    const comment = {
      ...(!!formState.text.value && { text: formState.text.value }),
      ...(!!sanitized && { html: sanitized }),
      ...(formState.attachments.value.nonEmpty && {
        attachments: formState.attachments.value.map((a) => {
          const { createdAt, attachmentUrl, downloadUrl, ...asset } = a.asset;
          return { asset };
        }),
      }),
      streamVisibility: CommentStreamVisibility.Trades,
    };
    // Update the current user's approver status to changes requested
    const updatedApproverStatus = await saveApprover({
      variables: {
        input: {
          id: currentUserApprover?.id ?? "",
          status: ApproverStatus.ChangesRequested,
          // save the comment to the approver user's bill approval
          comment,
        },
      },
      refetchQueries: [
        { query: InvoicePageDocument, variables: { billId: bill.id } },
        { query: InvoiceRequestChangesDocument, variables: { billId: bill.id } },
      ],
    });

    if (updatedApproverStatus.data?.saveApprover) {
      triggerNotice({
        message: <SucessMessage bill={bill} formState={formState} />,
      });
    }
  }, [bill, currentUserApprover?.id, formState, saveApprover, triggerNotice]);

  return (
    <div css={Css.px4.py3.oa.h100.f1.$}>
      <PageHeader
        left={
          <div css={Css.df.fdc.jcfs.xlSb.$}>
            <div>Request Changes</div>
            <InvoiceParentList parents={bill.parents} />
          </div>
        }
        right={<IconButton icon="x" onClick={closeRightPane} />}
      />

      <div>
        <div css={Css.baseMd.mt4.mb2.$} {...tid.reasonTitle}>
          What changes need to be made to this invoice?
        </div>
        <div css={Css.mb4.$} {...tid.reason}>
          <RichTextField value={formState.html.value} onChange={onEditorChange} readOnly={!canRequestChanges} />
        </div>
      </div>

      {orderedAttachments.nonEmpty && (
        <DocumentList assets={orderedAttachments.map((a) => a.value.asset) as AssetDetailsFragment[]} />
      )}

      {canRequestChanges && (
        <div css={Css.$}>
          <div css={Css.my2.$} {...tid.uploadDocumentTile}>
            Upload any relevant documents:
          </div>
          <UppyUploader
            onFinish={saveAttachmentToForm}
            dragDropText="Drag & Drop or Click to Upload files"
            maxNumberOfFiles={10}
            dragDropWidth={"100%"}
          />
        </div>
      )}

      <div css={Css.p1.df.aic.jcfe.bt.bcGray200.wPx(450).hPx(70).mxPx(-32).mt5.bgWhite.$}>
        <div css={Css.df.fdr.jcsb.$}>
          <Button variant="tertiary" label="Cancel Request" onClick={closeRightPane} />
          <Button
            variant="primary"
            label="Submit Request"
            onClick={() => handleRequestChanges()}
            disabled={(changeReason === "" && "Please include changes needed") || disabledReason}
          />
        </div>
      </div>
    </div>
  );
}

export interface SaveAssetType extends AssetInput {
  downloadUrl: string;
  attachmentUrl: string;
  contentType: string;
  createdAt: Date;
  version?: string;
}
export interface SaveAttachmentType extends SaveAttachmentInput {
  asset: SaveAssetType;
}

type RequestChangeForm = {
  text: string;
  html: string;
  attachments: SaveAttachmentType[];
};

const attachmentConfig: ObjectConfig<SaveAttachmentType> = {
  id: { type: "value" },
  asset: {
    type: "object",
    config: {
      id: { type: "value" },
      s3Key: { type: "value" },
      fileName: { type: "value" },
      contentType: { type: "value" },
      sizeInBytes: { type: "value" },
      downloadUrl: { type: "value" },
      attachmentUrl: { type: "value" },
      createdAt: { type: "value" },
      delete: { type: "value" },
    },
  },
};

const formConfig: ObjectConfig<RequestChangeForm> = {
  text: { type: "value", rules: [required] },
  html: { type: "value", rules: [required] },
  attachments: { type: "list", config: attachmentConfig },
};

function SucessMessage({
  bill,
  formState,
}: {
  bill: InvoiceRequestChangesQuery["bill"];
  formState: ObjectState<RequestChangeForm>;
}) {
  const superIntendent = useMemo(
    () => bill.project.teamMembers.find((u) => u.role.code === ProjectRole.Superintendent)?.user,
    [bill.project.teamMembers],
  );
  return (
    <div css={Css.df.fdc.jcsb.p1.gap1.xs.$}>
      <div css={Css.df.fdr.gap1.jcsb.bb.mb1.py1.aic.$}>
        Request Submitted!
        <Icon icon="checkCircle" />
      </div>

      <div css={Css.df.fdc.jcfe.gap1.$}>
        <div css={Css.xs.$}>Request sent to</div>
        {superIntendent && (
          <div css={Css.df.fdr.gap1.aic.$}>
            <Avatar src={superIntendent.avatar} />
            <div>{superIntendent.name}</div>
          </div>
        )}
      </div>

      <div css={Css.df.fdc.mt1.$}>
        <div css={Css.xs.$}> Changes requested for</div>
        <InvoiceParentList parents={bill.parents} />
      </div>

      <div css={Css.df.fdc.mt1.$}>
        <div css={Css.xs.$}> Changes requested</div>
        <div css={Css.smSb.$}>{formState.text.value}</div>
      </div>

      {formState.value.attachments.map((at) => (
        <div css={Css.df.fdr.mt1.gap1.$} key={at.id}>
          <Icon icon="file" />
          {at.asset.fileName}
        </div>
      ))}
    </div>
  );
}

export function DocumentList({ assets }: { assets: AssetDetailsFragment[] }) {
  const tid = useTestIds({});
  return (
    <div css={Css.mt3.$}>
      <div css={Css.smMd.$}>Documents</div>
      <div css={Css.mt3.$}>
        {assets.map((a, i) => (
          <div css={Css.df.fdc.w100.mb1.br8.$} key={a.id}>
            <div css={Css.dg.gtc("45px 245px 40px").gap2.tiny.p2.asfe.aic.br8.addIn("&:hover", Css.bgGray100.$).$}>
              <Icon icon="document" inc={3} />
              <div css={Css.df.fdc.$}>
                <div css={Css.mb1.tinyMd.$} {...tid.documentName}>
                  {a?.fileName}
                </div>
                <FormattedDate date={a?.createdAt} dateFormatStyle="short" timeFormatStyle="short" />
              </div>
              {a?.downloadUrl && <IconButton color={Palette.Blue500} inc={3} icon="download" onClick={a.downloadUrl} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function InvoiceParentList({ parents }: { parents: InvoiceRequestChangesQuery["bill"]["parents"] }) {
  return (
    <div css={Css.df.fdc.$}>
      {parents.map((p) =>
        p.__typename === "CommitmentChangeOrder" ? (
          <div key={p.id}>CO #{p.accountingNumber}</div>
        ) : (
          <div key={p.id}>PO #{p.accountingNumber}</div>
        ),
      )}
    </div>
  );
}
