import { Font, Only, Properties, useTestIds } from "@homebound/beam";
import { addDays, getISODay } from "date-fns";
import moment from "moment";

/**
 * Date format styles.
 *
 * none: Specifies no style, i.e. do not display.
 * short: Specifies a short style, typically numeric only, such as “11/23/37” or “3:30 PM”.
 * medium: Specifies a medium style, typically with abbreviated text, such as “Nov. 23, 1937” or “3:30:32 PM”.
 * long: Specifies a long style, such as “November 3, 1937” or “3:30:32 PM”.
 */
export const formattedDateStyles = ["none", "xshort", "short", "medium", "long"] as const;
type FormattedDateStyle = (typeof formattedDateStyles)[number];

export interface FormattedDateProps<X> {
  id?: string;
  xss?: X;
  date: Date | undefined;
  dateFormatStyle?: FormattedDateStyle;
  timeFormatStyle?: FormattedDateStyle;
  mdash?: boolean;
}

type FormattedDateXss = Pick<Properties, Font | "color">;

const dateStyleFormats: Record<FormattedDateStyle, string> = {
  none: "",
  xshort: "dddd, MMM. DD.", // Friday, June 6.
  short: "MM/DD/YY",
  medium: "MMM. DD, YYYY",
  long: "MMMM D, YYYY",
};

const timeStyleFormats: Record<FormattedDateStyle, string> = {
  none: "",
  xshort: "",
  short: "hh:mm a",
  medium: "hh:mm:ss a",
  long: "hh:mm:ss a",
};

/**
 * Formats a date with standard date/time styles and return a component.
 *
 * examples:
 *  dateFormatStyle=short, timeFormatStyle=none (default) -> 11/01/20
 *  dateFormatStyle=short, timeFormatStyle=short -> 11/01/20 03:30 pm
 *  dateFormatStyle=none,  timeFormatStyle=short -> 03:30 pm
 */
export function FormattedDate<X extends Only<FormattedDateXss, X>>(props: FormattedDateProps<X>) {
  const { xss, date, dateFormatStyle = "short", timeFormatStyle = "none", id = "FormattedDate", mdash } = props;

  const testIds = useTestIds({}, id);
  const unformattedMomentDate = moment(date);
  const formattedDate = formatDate(date, dateFormatStyle, timeFormatStyle);

  // TODO: figure out what to do here w/ date/time format. It seems like the intention
  // was to provide more detail in the hover? so instead of a 2 digit year a 4 digit year
  // maybe we should just bump up to next level of detail for date/time if specified?...
  const formattedHoverDate = unformattedMomentDate.format(dateStyleFormats["medium"]);

  const emptyContent = mdash ? "-" : "";
  const content = date === undefined ? emptyContent : formattedDate;

  return (
    <div css={xss} {...testIds} title={date ? formattedHoverDate : undefined}>
      {content}
    </div>
  );
}

/** Formats a date with standard date/time styles and return a string */
export function formatDate(
  date: Date | undefined,
  dateFormatStyle: FormattedDateStyle = "short",
  timeFormatStyle: FormattedDateStyle = "none",
) {
  if (date) {
    const format =
      [dateStyleFormats[dateFormatStyle], timeStyleFormats[timeFormatStyle]].filter((f) => f.length).join(" ") ||
      dateStyleFormats["short"]; // if dateFormatStyle=none & timeFormatStyle=none, use default date format

    const unformattedMomentDate = moment(date);
    return unformattedMomentDate.format(format);
  }
}

/**
 *
 * @param targetDayOfWeek - The nearest target dayOfWeek we want to search for
 * @param fromDate - defaults to current day if no fromDate param passed
 * @returns - The nearest target dayOfWeek based on `fromDate` param
 */
export function getDayInPast(targetDayOfWeek: string, fromDate = new Date()) {
  // Map based on the getISODay format. E.g. 7 for Sunday, 1 for Monday etc.
  const dayOfWeekMap: Record<string, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // get the ISODay for the desired dayOfWeek
  const targetISODay = dayOfWeekMap[targetDayOfWeek];
  const fromISODay = getISODay(fromDate);

  // if targetISODay >= fromISODay, -7 to offset a week
  const offsetDays = targetISODay >= fromISODay ? -7 + (targetISODay - fromISODay) : targetISODay - fromISODay;

  return addDays(fromDate, offsetDays);
}
