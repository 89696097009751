import { generatePath } from "react-router";
export type ProjectRouteParams = { projectId: string };
export type purchaseOrderParam = ProjectRouteParams & { purchaseOrderId?: string; changeOrderId?: string };
export type invoiceParam = ProjectRouteParams & { billId: string };

export const projectIdParam = ":projectId(p:\\d+|\\d+)";
export const purchaseOrderIdParam = ":purchaseOrderId(c:\\d+|\\d+)";
export const changeOrderIdParam = ":changeOrderId(cco:\\d+|\\d+)";
export const invoiceIdParam = ":billId(b:\\d+|\\d+)";

export const projectsPath = "/projects";
const projectPath = `${projectsPath}/:projectId(p:\\d*)`;

export const purchaseOrdersPath = "/purchase-orders";
export const invoicesPath = "/invoices";
export const schedulePath = "/schedule";
export const accountsSettingsPath = "/account-settings";

export const projectPaths = {
  project: projectPath,
  purchaseOrders: purchaseOrdersPath,
  purchaseOrder: `${projectPath}/purchase-orders/${purchaseOrderIdParam}`,
  changeOrder: `${projectPath}/purchase-orders/${purchaseOrderIdParam}/change-order/${changeOrderIdParam}`,
  invoices: invoicesPath,
  invoice: `${projectPath}/invoices/${invoiceIdParam}`,
  schedule: schedulePath,
};

/** Route urls */
export function getPurchaseOrderUrl(projectId: string, purchaseOrderId: string) {
  return generatePath(projectPaths.purchaseOrder, { projectId, purchaseOrderId });
}
export function getChangeOrderUrl(projectId: string, purchaseOrderId: string, changeOrderId: string) {
  return generatePath(projectPaths.changeOrder, { projectId, purchaseOrderId, changeOrderId });
}
export function getInvoiceUrl(projectId: string, billId: string) {
  return generatePath(projectPaths.invoice, { projectId, billId });
}
