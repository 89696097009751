import { ChipType } from "@homebound/beam";
import { BillStatus, CommitmentStatus } from "src/generated/graphql-types";
import { foldEnum } from "./utils";

export type CommitmentStatusName = ReturnType<typeof commitmentStatusToNameMapper>;
export function commitmentStatusToNameMapper(status: CommitmentStatus) {
  return status === CommitmentStatus.Voided
    ? "Voided"
    : status !== CommitmentStatus.Signed
    ? "Needs Signature"
    : "Released";
}

export function commitmentStatusNameToChipTypeMapper(name: CommitmentStatusName): ChipType {
  return foldEnum(name, {
    Voided: "neutral",
    "Needs Signature": "caution",
    Released: "info",
  });
}

/**
 * Simplify trade facing PO status for c2p launch:
 *
 * 1. Needs Signature: Lot released commitments are autosigned but
 * manual commitments may not yet have been signed, surface this.
 *
 * 2. Released: PO signed by both parties
 *
 * 3. Voided
 *
 */
export function commitmentStatusToChipTypeMapper(
  status: CommitmentStatus,
): [type: ChipType, label: CommitmentStatusName] {
  const name = commitmentStatusToNameMapper(status);
  return [commitmentStatusNameToChipTypeMapper(name), name];
}

export function billStatusChipTypeMapper(status: BillStatus): [type: ChipType, label: string] {
  // We're filtering out Draft, ReleaseScheduled, Unreleased, Cancelled and Rejected on the BE
  return foldEnum(status, {
    [BillStatus.PendingApproval]: ["caution", "Pending Approval"],
    [BillStatus.ReadyToPay]: ["info", "Ready to Pay"],
    [BillStatus.PartiallyPaid]: ["info", "Partially Paid"],
    [BillStatus.Paid]: ["success", "Paid"],
    [BillStatus.Reversed]: ["neutral", "Reversed"],
    [BillStatus.PendingConditionalLienWaiver]: ["caution", "Pending Cond. LW"],
    [BillStatus.PendingUnconditionalLienWaiver]: ["caution", "Paid - Pending Uncond. LW"],
    [BillStatus.Draft]: ["info", "Draft"],
    [BillStatus.ReleaseScheduled]: ["info", "RELEASE SCHEDULED"],
    [BillStatus.Unreleased]: ["warning", "UNRELEASED"],
    [BillStatus.Cancelled]: ["neutral", "CANCELLED"],
    [BillStatus.Rejected]: ["neutral", "REJECTED"],
    else: ["caution", "In Review"], // Consider change requested status for manuals as still in review
  });
}
